.button {
  $block: &;
  display: inline-block;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 16px;
  padding-right: 16px;
  margin-right: 10px;
  @include font($size: 15px, $spacing: 0.015em, $height: 17.58px, $weight: 500, $colour: map-get($palette, white));
  background: map-get($palette, red);
  border-radius: 4px;
  position: relative;
  transition: background 150ms ease, color 150ms ease, box-shadow 150ms ease;
  box-shadow: 0 2px 8px 0 rgba(map-get($palette, black), 0);

  text-decoration: none;
  border: 1px solid map-get($palette, transparent);

  &:hover {
    text-decoration: none;
    color: map-get($palette, white);
    box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.15);
    background: map-get($palette, redtwo);
  }

  &:active {
    background: map-get($palette, redtwo);
    box-shadow: none;
    border: 1px solid map-get($palette, nearlyblack);
  }

  &--outline {
    background: map-get($palette, transparent);
    border: 1px solid map-get($palette, red);
    color: map-get($palette, red);

    &:hover {
      background: map-get($palette, transparent);
      border-color: map-get($palette, redtwo);
      color: map-get($palette, redtwo);
    }

    &:active {
      background: map-get($palette, transparent);
      color: map-get($palette, nearlyblack);
      border-color: map-get($palette, nearlyblack);
    }
  }

  &--darkblue {
    background: map-get($palette, darkblue);
    border: 1px solid map-get($palette, darkblue);
    color: map-get($palette, white);
  }

  &--white {
    background: map-get($palette, transparent);
    border: 1px solid map-get($palette, white);
    color: map-get($palette, white);

    &:hover {
      background: map-get($palette, transparent);
      border-color: map-get($palette, white);
      color: map-get($palette, white);
    }

    &:active {
      background: map-get($palette, transparent);
      color: map-get($palette, nearlyblack);
      border-color: map-get($palette, nearlyblack);
    }
  }

  &--black {
    background: map-get($palette, transparent);
    border: 1px solid map-get($palette, black);
    color: map-get($palette, black);

    &:hover {
      background: map-get($palette, transparent);
      border-color: map-get($palette, black);
      color: map-get($palette, black);
    }

    &:active {
      background: map-get($palette, transparent);
      color: map-get($palette, black);
      border-color: map-get($palette, black);
    }
  }

  &--grey {
    background: map-get($palette, transparent);
    border: 1px solid map-get($palette, grey-pass);
    color: map-get($palette, grey-pass);

    &:hover {
      background: map-get($palette, transparent);
      border-color: map-get($palette, grey-pass);
      color: map-get($palette, grey-pass);
    }

    &:active {
      background: map-get($palette, transparent);
      color: map-get($palette, grey-pass);
      border-color: map-get($palette, grey-pass);
    }
  }

/*****
  Button Icons
  *****/
  &--right-icon-arrow {
    @include button-icon();
  }

  &--right-icon-arrow#{$block}--outline {
    @include button-icon($image-path: "/img/icons/arrow-right--red");
    &:active {
      @include button-icon($image-path: "/img/icons/arrow-right--nearlyblack");
    }
  }

  &--right-icon-arrow#{$block}--nearlyblack {
    @include button-icon($image-path: "/img/icons/arrow-right--nearlyblack");
    &:active {
      @include button-icon($image-path: "/img/icons/arrow-right--nearlyblack");
    }
  }

  &--right-icon-arrow#{$block}--grey {
    @include button-icon($image-path: "/img/icons/arrow-right--grey");
    &:active {
      @include button-icon($image-path: "/img/icons/arrow-right--grey");
    }
  }

  &--right-icon-arrow#{$block}--outline#{$block}--white {
    @include button-icon();
    &:active {
      @include button-icon($image-path: "/img/icons/arrow-right--nearlyblack");
    }
  }

  &--left-icon-arrow {
    @include button-icon($selector: ":before", $flip-icon: true);
  }

  &--left-icon-arrow#{$block}--outline {
    @include button-icon($selector: ":before", $image-path: "/img/icons/arrow-right--red", $flip-icon: true);
    &:active {
      @include button-icon($selector: ":before", $image-path: "/img/icons/arrow-right--nearlyblack", $flip-icon: true);
    }
  }

  &--left-icon-arrow#{$block}--nearlyblack {
    @include button-icon($selector: ":before", $image-path: "/img/icons/arrow-right--nearlyblack", $flip-icon: true);
    &:active {
      @include button-icon($selector: ":before", $image-path: "/img/icons/arrow-right--nearlyblack", $flip-icon: true);
    }
  }

  &--left-icon-arrow#{$block}--outline#{$block}--white {
    @include button-icon($selector: ":before", $flip-icon: true);
    &:active {
      @include button-icon($selector: ":before", $image-path: "/img/icons/arrow-right--nearlyblack", $flip-icon: true);
    }
  }

  &--left-icon-pdf {
    @include button-icon(
      $selector: ":before",
      $image-path: "/img/icons/icon-pdf",
      $width: 21px,
      $text-padding: 12px,
      $outer-padding: 17px
      );
  }

  &--left-icon-volume {
    @include button-icon(
      $selector: ":before",
      $image-path: "/img/icons/icon-volume",
      $width: 18px,
      $text-padding: 13px,
      $outer-padding: 19px
      );
  }

  &--left-icon-document#{$block}--outline {
    @include button-icon(
      $selector: ":before",
      $image-path: "/img/icons/icon-document",
      $width: 18px,
      $text-padding: 13px,
      $outer-padding: 19px
      );
    &:active {
      @include button-icon(
        $selector: ":before",
        $image-path: "/img/icons/icon-document--nearlyblack",
        $width: 18px,
        $text-padding: 13px,
        $outer-padding: 19px
        );
    }
  }

  &--left-icon-document#{$block}--grey {
    @include button-icon(
      $selector: ":before",
      $image-path: "/img/icons/icon-document--grey",
      $width: 18px,
      $text-padding: 13px,
      $outer-padding: 19px
      );
  }
}
