blockquote {
  padding-left: 20px;
  padding-top: 10px;
  border-left: 6px solid map-get($palette, red);
  @include font($size: 23px, $weight: 700, $spacing: 0, $height: 34.5px, $colour: #000000);
  font-style: italic;
  margin-bottom: 0;
  padding-right: 20px;

  p {
    margin-bottom: 0;
  }

  footer {
    margin-top: 7px;
    padding-bottom: 15px;
    @include font($size: 16px, $weight: 700, $spacing: 0.015em, $height: 24px, $colour: map-get($palette, greyblue));
  }
}