body {
	margin: 0 auto;
	overflow-x: hidden;

	&.modal-open {
		overflow: hidden;
	}
}

%table {
	border: none;
	width: 100%;
	margin: 40px 0 51px 0;

	td, th {
		border: none;
		padding: 13px 18px;
	}

	th {
		background: transparent;
    text-transform: uppercase;
    @include font($size: 13px, $weight: 500, $height: 16px, $spacing: 0.01em, $colour: map-get($palette, black));
	}

	td {
    @include font($size: 16px, $weight: 300, $height: 16px, $spacing: 0.015em, $colour: map-get($palette, black));

	}

	tbody {
		tr:nth-child(odd) {
			background: map-get($palette, light-grey);
		}
	}
}

.wysiwyg {
	ul, ol {
		list-style: none;
		padding-left: 20px;
		margin: 0px 0px 15px;

		li {
			margin: 0px 0px 5px;
			line-height: 1.69;

			p {
				margin: 0px;
				line-height: 1.69;
				vertical-align: top;
			}
		}
	}

	ul {
		li {
			position: relative;

			&:before {
				content: '•';
				vertical-align: top;
				position: absolute;
				top: -2px;
				left: -15px;
			}
		}
	}

	ol {
		list-style: decimal;
	}

	table {
		@extend %table;
	}


	&--top-border:before {
		content: " ";
		display: block;
		width: 143px;
		height: 6px;
		background-color: map-get($palette, red);
		margin-bottom: 19px;
	}

  .styledImage {
    .fr-inner {
      float: left;
      padding-top: 30px !important;
      color: black;
    }
  }

  .styledImageResponsive {
    background-color: transparent;
    max-width: 100%;
    height: auto !important;
  }

  .styledImageRoundCorners {
    // box-shadow: 0px 38px 38px rgba(0, 0, 0, 0.07), 0px 24.6296px 22.2546px rgba(0, 0, 0, 0.0531481), 0px 14.637px 12.1037px rgba(0, 0, 0, 0.0425185), 0px 7.6px 6.175px rgba(0, 0, 0, 0.035), 0px 3.0963px 3.0963px rgba(0, 0, 0, 0.0274815), 0px 0.703704px 1.49537px rgba(0, 0, 0, 0.0168519);
    border-radius: 3px;
  }

  .styledImageBoxShadow {
    box-shadow: 0px 38px 38px rgba(0, 0, 0, 0.07), 0px 24.6296px 22.2546px rgba(0, 0, 0, 0.0531481), 0px 14.637px 12.1037px rgba(0, 0, 0, 0.0425185), 0px 7.6px 6.175px rgba(0, 0, 0, 0.035), 0px 3.0963px 3.0963px rgba(0, 0, 0, 0.0274815), 0px 0.703704px 1.49537px rgba(0, 0, 0, 0.0168519);
    border-radius: 3px;
  }
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  margin: 0 auto;
  padding: 0;
  display: block;
}

.block-link-nostyle{
	display: block;
	color: unset;
	text-decoration: none;
	&:hover{
	 	color: unset;
	 }
}
