.socialMediaShare {
  $block: &;

  height: 78px;
  text-align: right;
  margin-bottom: 34px;

  &--contain {
    position: relative;
    top: calc(100% - 34px);
    padding: 4px;

    span {
      @extend .body-text--light;
      padding-left: 19px;
    }

    a {
      padding-left: 19px;
      color: transparent;
      text-decoration: none;
    }
  }

}
