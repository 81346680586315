.contactDetails {
  margin-top: 28px;

  @include respond(md) {
    margin-top: 38px;
  }

  &__address {
    margin-bottom: 19px;
  }

  &--redText {
    color: map-get($palette, red);
  }
}
