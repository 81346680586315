.locationSelect {
  $block: &;

  .col-12, .col-md-7 {
    padding: 0;
  }

  .row {
    margin: 0;
  }

  &--map {
    height: 377px;
    border-bottom: 20px solid map-get($palette, red);
    border-left: 0;

    @include respond(md) {
      border-left: 20px solid map-get($palette, red);
      border-bottom: 0;
      padding-top: 20px;

    }

    @include respond(lg) {
      padding-top: 47px;
      height: 677px;
    }
  }

  &__breadcrumbs-padding {
    margin-left: 16px;
    margin-right: 16px;
    padding-top: 30px;
    padding-bottom: 0;

    @include respond(md) {
      padding-left: 0;
      padding-right: 0;
    }

    @include respond(lg) {
      padding-left: 50px;
      padding-right: 48px;
      padding-top: 50px;
    }
  }

  &__title {
    padding-top: 48px;
  }

  &__text-padding {
    margin: 0 16px;
    padding: 0;

    a {
      color: map-get($palette, red);
    }

    @include respond(md) {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
    }

    @include respond(lg) {
      padding-left: 50px;
      padding-right: 48px;
      padding-top: 22px;
      padding-bottom: 0;
    }
  }

  &__select-box-padding {
    padding: 0 8px;
  }

  &--selectBox {
    border-radius: 6px;
    height: 160px;
    width: 100%;
    background:linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%), #007480;
    padding: 38px 24px;

    @include respond(md) {
      max-width: 460px;
    }

    .selectric-wrapper {
      .button {
        background: none;
        margin-right: 0;
        box-shadow: none;
        color: black;
      }
    }

    &--row {
      cursor: pointer;
      margin-top: 22px;
      margin-left: 20px;
      color: white;
      span {
        margin-left: 19px;
      }
    }
  }

  &--accordion {
    margin: 30px auto 70px auto;

    .accordion-item {
      border-bottom: 1px solid map-get($palette, medium-grey);
    }

    @include respond(md) {
      max-width: 857px;
    }

    &--item {

      .row {
        padding-top: 28px;
      }

      &--icon-col {
        width: 30px;
        padding: 0 30px 0 20px;
        img {
          width: 25px;
          height: 25px;
        }
      }

      &--content-col {
        width: auto;
        padding: 0 20px 0 20px;
        flex: 100;
      }

      &--btn {
        padding-left: 55px;
        padding-top: 0 !important;
      }

      &--btn > .col-auto {
        padding-top: 24px;
      }
    }
  }
}
