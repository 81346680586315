$components: ("Pages", "Articles");

@each $component in $components {
  .related#{$component} {
    margin-top: 50px;
    margin-bottom: 40px;

    @include respond(md) {
      margin-bottom: 100px;
    }

    &__title {
      margin-bottom: 50px;

      @include respond(md) {
        margin-bottom: 28px;
      }
    }

    .button {
      text-transform: capitalize;
    }
  }
}
