.featuredCard {
  $block: &;

  height: 328px;
  border-radius: 3px;
  background-repeat: no-repeat!important;
  background-position: center!important;
  background-size: cover!important;
  position: relative;

  &__text {
    z-index: 3;
    position: absolute;
    margin: 24px 21px 21px;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;

    @include respond(lg) {
      max-width: 463px;
    }
  }

  &__subtitle {
    color: map-get($palette, white);
    position: absolute;
  }

  h4 {
    color: map-get($palette, white);
    margin-bottom: 0;
    position: absolute;
    bottom: 0;
  }

  &:after {
    content:"";
    position: absolute;
    top:0;
    left:0;
    height:100%;
    width:100%;
    border-radius: 3px;
    background: linear-gradient(0deg, map-get($palette, black-half-transparent), map-get($palette, black-half-transparent));
  }

  &--promo {
    &:after {
      display: none;
    }

    & #{$block}__text {
      background-color: map-get($palette, white);
      padding: 21px;
      rigth: unset;
      top: unset;
    }

    h4 {
      color: map-get($palette, black);
      margin-bottom: 10px;
      position: relative;
    }
  }
}

