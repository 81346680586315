.promoPanelRowTwo {
  padding: 85px 0 69px 0;

  @include respond(md) {
    padding: 85px 0 48px 0;
  }

  @include respond(lg) {
    padding: 80px 0 64px 0;
  }

  h3 {
    margin-bottom: 24px;

    @include respond(lg) {
      margin-bottom: 38px;
    }
  }

  .col-12.col-md-6:not(:last-of-type) {
    .featuredCard {
      margin-bottom: 40px;

      @include respond(md) {
        margin-bottom: 0;
      }
    }
  }

  &--grey-bg {
    background-color: map-get($palette, light-grey)
  }
}
