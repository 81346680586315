$container-max-widths: (
sm: 550px,
md: 750px,
lg: 920px,
xl: 1100px,
xxl: 1300px
) !default;

$grid-breakpoints: (
xs: 0,
sm: 576px,
md: 768px,
lg: 992px,
xl: 1200px,
xxl: 1440px
);

$grid-gutter-width: 1.5rem !default;
