.kentico-form form {
  background-color: map-get($palette, light-grey);
  padding: 20px;
  margin-top: 35px;

  @include respond(lg) {
    padding: 88px 111px 80px 80px;
  }

  .form-field {
    clear: both;

    @include respond(md) {
      display: flex;
      align-items: flex-start;
    }
  }

  .form-field input[type="text"],
  .form-field input[type="email"],
  .form-field textarea {
    width: 100%;
    padding: 8px 16px;
    margin-bottom: 15px;
    border-radius: 3px;
    border: 1px solid map-get($palette, input-grey);
  }

  .form-field input[type="text"],
  .form-field input[type="email"] {
    height: 40px;
  }

  .explanation-text,
  .control-label[for*="FileUploader"],
  .form-field--recaptcha .form-field-label {
    display: none;
  }

  label {
    @include font($size: 16px, $weight: 400, $spacing: 0.015em, $height: 24px, $colour: map-get($palette, black));
    display: inline;
  }

  .form-field-label {
    margin-bottom: 5px;

    @include respond(md) {
      width: 36%;
    }
  }

  input {
    @include font($size: 16px, $weight: 400, $spacing: 0.015em, $height: 24px, $colour: map-get($palette, black));

    &:placeholder {
      opacity: 0.36;
    }
  }

  .selectric {
    background-color: map-get($palette, white);
    margin-bottom: 10px;
    border-radius: 3px;
    border: 1px solid map-get($palette, input-grey);
    height: 40px;

    .label {
      padding: 8px 16px;
      margin: 0;
      height: 40px;
      opacity: 0.8;
      @include font($size: 16px, $weight: 400, $spacing: 0.015em, $height: 24px, $colour: map-get($palette, black));
    }

    .button {
      background: map-get($palette, white);
      margin-right: 0;

      &:after {
        border: none;
        background: url('/img/icons/arrow-right--black.svg');
        background-repeat: no-repeat;
        background-size: 15px 18px;
        width: 15px;
        height: 14px;
      }

      &:hover {
        box-shadow: none;
      }
    }
  }

  .form-field--recaptcha{
    .editing-form-control-nested-control {
      margin-top: 20px;

      @include respond(md) {
        margin-left: 36%;
      }
    }

    .field-validation-error {
      margin-top: 5px;
    }
  }

  .form-field--fileUploader {
    margin-bottom: 65px;

    @include respond(md) {
      margin-bottom: 0;
    }

    .editing-form-control-nested-control {
      position: relative;

      @include respond(md) {
        width: 100%!important;
      }

      .ktc-file-component-wrapper {
        @include respond(md) {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }

    .form-field-label {
      display: none;
    }
  }

  label[for*="FileUploader"] {
    width: 100%;

    @include respond(md) {
      width: 36%;
    }
  }

  input[type="file"] {
    background-color: map-get($palette, white);
    color: map-get($palette, grey-pass);
    padding: 8px 16px;
    margin-bottom: 15px;
    border-radius: 3px;
    border: 1px solid map-get($palette, input-grey);
    width: calc(100% - 126px);
    float: right;
    margin-top: 5px;

    @include respond(md) {
      float: none;
      width: calc(64% - 126px);
    }

    &::-webkit-file-upload-button {
      background-color: map-get($palette, transparent);
      color: map-get($palette, red);
      -webkit-appearance: none;
      border: 1px solid map-get($palette, red);
      padding: 8px 16px;
      border-radius: 4px;
      font-size: 15px;
      font-weight: 500;
      cursor: pointer;
      margin-right: 18px;
      position: absolute;
      left: 0;
      top: 30px;
      height: 42px;
      display: inline-block;

      @include respond(md) {
        left: 36%;
        top: 5px;
      }
    }
  }

  [type="submit"] {
    display: inline-block;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 16px;
    padding-right: 42px;
    margin-right: 10px;
    margin-top: 16px;
    @include font($size: 15px, $spacing: 0.015em, $height: 17.58px, $weight: 500, $colour: map-get($palette, white));
    background: map-get($palette, red);
    border-radius: 4px;
    position: relative;
    transition: background 150ms ease, color 150ms ease, box-shadow 150ms ease;
    box-shadow: 0 2px 8px 0 rgba(map-get($palette, black), 0);
    text-decoration: none;
    border: 1px solid map-get($palette, transparent);

    @include button-icon();

    @include respond(md) {
      margin-left: 36%;
    }

    &:hover {
      text-decoration: none;
      color: map-get($palette, white);
      box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.15);
      background: map-get($palette, redtwo);
    }

    &:active {
      background: map-get($palette, redtwo);
      box-shadow: none;
      border: 1px solid map-get($palette, nearlyblack);
    }
  }

  input[type="checkbox"] {
    opacity: 0;
    width: 0;
    margin-bottom: 10px;

    @include respond(md) {
      position: absolute;
    }
  }

  input[type="checkbox"]+label {
    position: relative;
    padding-left: 24px;

    @include respond(md) {
      width: 100%;
    }

    &:hover {
      &:before {
        background-color: map-get($palette, input-grey);
      }
    }

    &:before {
      content: '';
      position: absolute;
      width: 28px;
      height: 28px;
      border-radius: 3px;
      cursor: pointer;
      background: url('/img/icons/checkbox-unchecked.svg');
      background-size: 18px 18px;
      background-position: center center;
      background-repeat: no-repeat;
      left: -8px;
    }
  }

  input[type="checkbox"]:checked+label {
    &:before {
      background: url('/img/icons/checkbox-checked.svg');
      background-size: 18px 18px;
      background-position: center center;
      background-repeat: no-repeat;
    }

    &:hover {
      &:before {
        background-color: map-get($palette, input-grey);
      }
    }
  }

  input[type="checkbox"][disabled="disabled"]+label {
    &:before {
      cursor: not-allowed;
    }
  }

  input[type="checkbox"][disabled="disabled"]:checked+label {
    &:before {
      background: url('/img/icons/checkbox-checked--disabled.svg');
      background-size: 18px 18px;
      background-position: center center;
      background-repeat: no-repeat;
    }
  }

  .editing-form-control-nested-control {
    @include respond(md) {
      width: 64%;
    }
  }

  // errors
  .form-field--validationError {
    .control-label,
    .form-field-label {
      color: map-get($palette, red);
    }
  }

  .form-field input[type="text"].input-validation-error,
  .form-field input[type="email"].input-validation-error,
  .form-field textarea.input-validation-error {
    border: 1px solid map-get($palette, red);
  }

  .field-validation-error {
    color: map-get($palette, red);
    text-transform: uppercase;
    font-weight: bold;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.1em;
    padding-left: 16px;
    margin-top: -4px;
    margin-bottom: 10px;
  }
}
