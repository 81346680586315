.productGSRow {
	margin-bottom: 45px;

	@inlcude respond(lg) {
		margin-bottom: 90px;
	}

	&--oneCol {
		.productCard--range {
			@include respond(md) {
				display: flex;
		    align-items: center;
			}

			.productCard__background {
				@include respond(md) {
					margin-right: 24px;
					position: relative;
			    width: 100%;
			    height: unset;
			    padding-bottom: 35%;
				}

				@include respond(xl) {
			    max-width: 384px;
			    padding-bottom: 19.5%;
				}
			}

			.productCard__background-content {
				@include respond(md) {
					width: 100%;
			    height: 100%;
			    max-height: unset;
			    position: absolute;
			    left: 0;
			    object-fit: cover;
				}
			}

			.productCard__text {
				@include respond(md) {
					margin: 0;
				}
			}

			.productCard__buttons {
				@include respond(xl) {
					flex-direction: column;
				}

				.button:first-of-type {
					margin-bottom: 15px;
				}
			}
		}
	}
}
