

.accordion-button {
  display: block;
  @include font($size: 23px, $weight: 700, $spacing: 0, $height: 34.5px, $colour: map-get($map: $palette, $key: teal));
  position: relative;
  padding: 16px;
  padding-right: 50px;
  text-decoration: none;
  background-color: map-get($map: $palette, $key: light-grey);
  border: none;
  width: 100%;
  text-align: left;


  &:before,
  &:after {
    content: ' ';
    display: block;
    height: 9px;
    width: 15px;
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    background-image: url('/img/icons/arrow-up-green.png');
    background-image: none, url('/img/icons/arrow-up-green.svg');
  }

  &:after {
    opacity: 0;
    background-image: url('/img/icons/arrow-down-black.png');
    background-image: none, url('/img/icons/arrow-down-black.svg');
  }

  &.collapsed {
    background-color: transparent;
    color: map-get($palette, nearlyblack);
    &:hover {
      color: map-get($map: $palette, $key: teal);
    }
  }

  &.collapsed:after {
    opacity: 1;
  }

  &.collapsed:before {
    opacity: 0;
  }

  &:hover{
    text-decoration: none;
    color: black;
  }
}

.accordion-body {
  padding: 16px;
  @include font($size: 16px, $weight: 300, $spacing: 0.015em, $height: 1.5, $colour: map-get($palette, nearlyblack));
}