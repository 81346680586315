@mixin font($size: 14px, $family: $font-family, $weight: 400, $colour: map-get($palette, white), $spacing: normal, $height: 1.2) {
  font-family: $family;
  font-size: $size;
  font-weight: $weight;
  font-style: normal;
  color: $colour;
  letter-spacing: $spacing;
  line-height: $height;
}

@mixin respond($size) {
  @if $size == xxl or $size == xl or $size == lg or $size == md or $size == sm or $size == xs {
    @media screen and (min-width: map-get($grid-breakpoints, $size)) {
      @content;
    }
  }
  @else {
    @media screen and (min-width: $size) {
      @content;
    }
  }
}

@mixin button($background: -1, $colour: -1, $hover: -1, $border: -1) {
  padding: 11px 20px;

  @include font($size: 14px, $weight: 700);

  line-height: 1.2;
  border-radius: 100px;
  transition: background 150ms ease, color 150ms ease;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    outline: map-get($palette, squash) auto 5px;
  }

  @if $background != -1 {
    background: $background;
  }

  @if $colour != -1 {
    color: $colour;

    &:hover {
      color: $colour;
    }
  }

  @if $border != -1 {
    border-color: $border;
  }

  @if $hover != -1 and $background {
    @if $background != -1 {
      &:hover {
        background: darken($background, 7%);
      }
    }
    @else {
      &:hover {
        opacity: 0.5;
      }
    }
  }
  @else {
    &:hover {
      background: $background;
    }
  }
}

@mixin placeholder {
  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    @content;
  }

  &::-moz-placeholder { /* Firefox 19+ */
    @content;
  }

  &:-ms-input-placeholder { /* IE 10+ */
    @content;
  }

  &:-moz-placeholder { /* Firefox 18- */
    @content;
  }
}

%iconFont {
  font-family: $font-family--icon !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin button-icon($selector: ":after", $image-path: "/img/icons/arrow-right", $width: 10px, $text-padding: 16px, $outer-padding: 16px, $flip-icon: false) {
  @if $selector == ":before" {
    padding-left: $text-padding + $width + $outer-padding;
  }
  @else {
    padding-right: $text-padding + $width + $outer-padding;
  }

  &#{$selector} {
    content: " ";
    display: block;
    width: $width;
    height: 100%;
    background-size: $width auto;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url(#{$image-path}.png);
    background-image: none, url(#{$image-path}.svg);
    position: absolute;
    top: 50%;

    @if $flip-icon {
      transform: translateY(-50%) scale(-1);
    }
    @else {
      transform: translateY(-50%);
    }

    @if $selector == ":before" {
      left: $outer-padding;
    }
    @else {
      right: $outer-padding;
    }
  }
}

%hightlight-child-heading-on-hover {
  & h1, h2, h3,
  h4, h5, h6 {
    line-height: 135%;
    display: inline;
    transition: background 0.4s ease-in-out,color 0.4s ease-in-out;
    background-color: map-get($palette, red);
    background: linear-gradient(to bottom, map-get($palette, red) 0, map-get($palette, red) 100%);
    background-position: 0 100%;
    background-repeat: repeat-x;
    background-size: 0 0;
  }

  &:hover {
    & h1, h2, h3,
    h4, h5, h6 {
      color: map-get($palette, white);
      // background-color: map-get($palette, red);
      box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.15);
      line-height: 135%;
      background-size: 0.625rem 3.125rem;
    }
  }
}
