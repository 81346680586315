.map {
  $block: &;
  width: 100%;
  height: 357px;
  margin: 0 auto;
  padding: 0;
  background-color: #CAD2D3;

  @include respond(lg) {
    height: 630px;
    width: 1250px;
  }

  &--infoBox {
    padding: 12px 18px;

  }

  &--infoBoxTitle {
      @include font($size: 16px, $weight: 700, $spacing: 0.023em, $height: 24px, $colour: map-get($palette, black));
      display: block;
      padding-bottom: 9px;
    }

  &--infoBoxContentTitle {
      @include font($size: 11px, $weight: 700, $spacing: 0.023em, $height: 13px, $colour: map-get($palette, red));
      display: block;
      padding-bottom: 1px;
    }

  &--infoBoxContentItems {
    @include font($size: 11px, $weight: 500, $spacing: 0.023em, $height: 24px, $colour: map-get($palette, black));
    display: block;
    padding-bottom: 12px;
  }

  &--infoBoxContentItem {
    display: inherit;
  }
}

/**
  * Google map styling
 */
.gm-style-iw {
  min-width: 220px !important;
  top: 0 !important;
  left: 0 !important;
  background-color: #fff;
  box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 4px !important;
  padding: 0px !important;
}

.gm-ui-hover-effect {
  display: none !important;
}

.gm-style .gm-style-iw-t::after {
  display: none;
}

.gm-style-pbc {
  opacity: 0 !important;
}

.gm-style-moc {
  display: none;
}
