.tabbed-panel {
  position: relative;
  // background-color: map-get($map: $palette, $key: light-grey);

  .accordion__item {
    border-width: 1px 0px 1px;

    @include respond(lg) {
      border-width: 1px;
    }
  }

  a.tabbed-panel__link {
    display: block;
    color: map-get($map: $palette, $key: teal);
    background-color: map-get($map: $palette, $key: grey-half-transparent-bg);
    font-size: 16px;
    position: relative;
    line-height: 33px;
    padding: 18px 20px;
    font-family: $font-family;
    width: 100%;
    border-bottom: 1px solid map-get($map: $palette, $key: grey-half-transparent-border);
    text-decoration: none;
    text-align: center;
    font-weight: 700;

    &:before {
      content: '';
      width: 0;
      height: 8px;
      background-color: map-get($map: $palette, $key: red);
      position: absolute;
      left: 0;
      bottom: 0;

      @include respond(lg) {
        width: 8px;
        height: 0;
        top: 50%;
        bottom: auto;
        transform: translateY(-50%);
      }
    }

    &.is-active {
      color: map-get($map: $palette, $key: $black);
      background-color: $white;
      &:before {
        width: 100%;
        @include respond(lg) {
          height: 100%;
          width: 8px;
        }
      }
      @include respond(lg) {
        background-color: map-get($map: $palette, $key: light-grey);
      }
    }

    &:after {
      // @include chevronDown();
      right: 30px;
    }

    @include respond(lg) {
      &:last-child {
        border-bottom: 0;
      }

      &:after {
        transform: rotate(45deg);
        top: 42px;
        right: 27px;
      }

    }

    &:active,
    &:focus {
      text-decoration: none;
    }

    &.is-active {

      // color: map-get($map: $palette, $key: white);
      background-color: map-get($map: $palette, $key: blueDark);
      border-color: map-get($map: $palette, $key: grey-half-transparent-border);
    }
  }

  .tabbed-panel__tabs {
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: map-get($map: $palette, $key: grey-half-transparent-border);
  }

  .tabbed-panel__item {
    &:last-child {
      .tabbed-panel__link {
        border-bottom: 0;
      }
    }
  }

  .tabbed-panel__body {
    padding: 1.7rem 0;
    @include respond(lg) {
      padding: 25px 70px;
    }
  }

  &--horizontal {
    .tabbed-panel__tabs-wrapper {
      background-color: map-get($palette, light-grey);
      border-bottom: 1px solid map-get($palette, grey-half-transparent-border);

      .container {
        @media (max-width: 991px) {
          padding: 0;
          max-width: unset;
        }
      }
    }

    .tabbed-panel__tabs {
      border: none;
      white-space: nowrap;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      scrollbar-width: none;
      flex-wrap: nowrap;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    a.tabbed-panel__link {
      display: inline-block;
      width: auto;
      border-bottom: 0;
      background-color: transparent;
      border-left: 1px solid map-get($map: $palette, $key: grey-half-transparent-border);
      color: map-get($map: $palette, $key: grey-pass);


      &:first-child {
        border-left: 0;
        // border-right: 1px solid map-get($map: $palette, $key: grey-half-transparent-border);
      }

      &.is-active {
        color: map-get($map: $palette, $key: $black);
        background-color: transparent;
        &:before {
          width: 100%;
          height: 8px;
          bottom: -4px;
          top: auto;
        }
      }
    }
    .tabbed-panel__item {
      font-size: initial;
    }
  }

  @include respond(lg) {
    border-width: 0;

    &--horizontal {
      font-size: 0;
      background-color: transparent;

      .tabbed-panel__body {
        padding: 6rem 0;
      }


    }

    &--vertical {

      .tabbed-panel__tabs {
        float: left;
        width: 230px;
      }

      .tabbed-panel__item {
        padding-left: 230px;
        
      }

      .tabbed-panel__body {
        background-color: map-get($map: $palette, $key: light-grey);
      }

      .tabbed-panel__header {
        position: absolute;
        left: 0;
        width: 230px;
      }
    }
  }
}
