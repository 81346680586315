.iconDescriptionList {
  $block: &;

  &--row {
    display: inline-flex;
    padding: 0 0 4px 0;
    width: 100%;

    .icon {
      width: 80px;
      height: 80px;
      background-color: #C4C4C4;
      padding: 10px;

      img {
        width: auto;
        height: 100%;
      }
    }

    .description {
      width: 100%;
      min-height: 80px;
      background-color: map-get($palette, grey-pass);
      padding: 13px 17px;
      margin-left: 4px;
    }
  }
}
