.header {
	&__geolocation {
		background-color: map-get($palette, greyblue);
		padding: 23px 0;
		position: relative;
		z-index: 3;

		.container {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.wysiwyg {
			p {
				color: map-get($palette, white);
				margin-bottom: 0;
				font-weight: 300;
				font-size: 16px;
				line-height: 150%;
			}
		}

		&-close {
			cursor: pointer;
			align-self: baseline;
	    width: 30px;
	    margin-top: -2px;

	    @include respond(md) {
				align-self: auto;
		    width: 14px;
		    margin-top: -26px;
		    margin-left: 30px;
	    }

	    @include respond(lg) {
		    margin-top: 0;
		    margin-left: 0;
	    }

			img {
				width: 100%;
			}
		}
	}

	&__navigation {
    background-color: map-get($palette, white);
    position: relative;
		z-index: 1;
		transition: all 0.5s;

    ul {
   	  padding-left: 0;
    }
	}

	&__topLevel {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 22.55px 0;

		@include respond(lg) {
			padding: 29px 0 42px;
    }

    &-wrapper {
    	background-color: map-get($palette, white);
			position: relative;
			z-index: 200;
    	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    	transition: height 0.5s;

    	@include respond(lg) {
	    	border-bottom: 1px solid map-get($palette, medium-grey);
	    	box-shadow: none;
    	}
    }
	}

	&__logo {
		width: 183px;
    display: block;

    @include respond(lg) {
			width: 250px;
    }

    img {
    	width: 100%;
    }
	}

	&__right {
		display: flex;
		align-items: center;

		&-links {
			margin-right: 19px;
			align-items: center;
			color: map-get($palette, grey);

			a {
				text-decoration: none;
				font-weight: bold;
				font-size: 13px;
				line-height: 16px;
				color: map-get($palette, black);
				padding: 0 27px;
			}
		}
	}

	&__navbar {
    background-color: map-get($palette, white);
		z-index: 1;
		transition: height 5s;

		@include respond(lg) {
    	border-bottom: 1px solid map-get($palette, grey-transparent);
  	}

  	& > .container {
  		@include respond(lg) {
	    	display: flex;
		    align-items: center;
	  	}
  	}
	}

	&__navbar-logo {
		display: none;

		@include respond(lg) {
			display: block;
			overflow: hidden;
			width: 0;
			transition: width 0.5s;
		}

		.header__logo {
			width: 175px;
		}

		img {
    	width: 100%;
    }
	}

	&__active-item {
		@include respond(lg) {
			color: map-get($palette, red)!important;
			font-weight: 700!important;
			background-color: map-get($palette, grey-half-transparent-bg)!important;
		}

		& > a {
			@include respond(lg) {
				color: map-get($palette, red)!important;
				font-weight: 700!important;
			}
		}
	}

	&__level1 {
		@include respond(lg) {
			padding-top: 35.5px;
			background-color: map-get($palette, white);
	    min-height: 521px;
		}

		& > .dropdown-item.dropdown-submenu {
			@include respond(lg) {
				padding-left: 10px!important;
		    margin-left: -10px;
		    padding-right: 20px;
		    width: unset;
		  }

	    &:hover,
	    &:focus {
				@include respond(lg) {
					background-color: map-get($palette, grey-half-transparent-bg)!important;
				}
	    }
		}
	}

  &__level2.dropdown-menu {
  	top: 0!important;

		@include respond(lg) {
			width: 75%!important;
			border-left: 1px solid map-get($palette, grey-transparent)!important;
  	}

  	.container {
	    margin: 0;

			@include respond(lg) {
				width: 40%;
		    padding-top: 18.5px;
		    padding-left: 0;
			}
		}

  	// & > .container + .container {
			// @include respond(lg) {
			// 	width: 40%;
		 //    margin: 0;
		 //    padding-top: 18.5px;
		 //    padding-left: 0;
			// }
  	// }

  	& > .container > ul > li {
  		@include respond(lg) {
  			padding-left: 20px;
			}
  	}

  	& > .container > .dropdown-item.dropdown-subSubmenu {
			&:hover,
	    &:focus {
				@include respond(lg) {
					background-color: map-get($palette, grey-half-transparent-bg)!important;
				}
	    }
		}

  	&.show {
  		@include respond(lg) {
	  		left: 25%!important;
			}
  	}
  }

  &__level3.dropdown-menu {
  	top: 0!important;

		@include respond(lg) {
			width: 0!important;
	    overflow: hidden;
			border-left: 1px solid map-get($palette, grey-transparent)!important;
  	}

  	&.show {
  		@include respond(lg) {
  			width: 40%!important;
  			left: 35%!important;
  		}

  		@include respond(xl) {
  			left: 36%!important;
  		}
  	}

  	& > .container {
  		@include respond(lg) {
  			padding-left: 0;
		    padding-top: 33px;
  		}
  	}

  	& > .container > ul > li {
  		@include respond(lg) {
  			padding-left: 20px;
			}
  	}
  }

	&__back {
    @include font($size: 19px, $weight: 700, $height: 23px, $spacing: 0.015em, $colour: map-get($palette, black));
		text-decoration: none;
		display: flex;
		align-items: center;
		padding-top: 9px;
		padding-bottom: 9px;

		&:hover {
			color: map-get($palette, black);
		}

		&:before {
			content: '';
			display: inline-block;
			background: url('/img/icons/arrow-right--black.svg');
  		transform: rotate(90deg);
	    width: 15px;
	    height: 8px;
	    background-size: 15px 8px;
	    margin-bottom: 3px;
	    margin-right: 10px;
		}
	}

	&__dropdownList-title {
  	@include font($size: 19px, $weight: 700, $height: 23px, $spacing: 0.013em, $colour: map-get($palette, black));
  	margin-bottom: 0;
  	display: block;
    padding: 9px 0;
  }

  &__dropdown.dropdown-menu {
	  @include respond(lg) {
	  	height: unset!important;
	  	min-height: 521px;
	  	left: 0 !important;
	  	top: -200vh!important;
	  	z-index: -1;
	  	transition: top 0.5s!important;
	    border-top: 1px solid map-get($palette, grey-transparent)!important;
	  }

	  & > .container + .container {
		  @include respond(lg) {
		  	position: relative;
		  }
	  }
  }

  &__dropdown.dropdown-menu.show {
	  @include respond(lg) {
	  	top: 178px!important;
	  }
  }

  &__promoPanel {
  	min-height: 521px;
    border-left: 1px solid map-get($palette, grey-transparent);
    padding-top: 64px;
    padding-left: 64px;
  }

  // language selector dropdown
  &__language {
  	position: relative;
  	margin-right: 29px;

  	@include respond(lg) {
	  	margin-right: 0;
  	}

  	&-toggle {
  		display: flex;
	    align-items: center;
  		text-decoration: none;
	    height: 20px;
	    width: 42px;

	    .region-selector-black {
	    	display: block;
	    }

	    .region-selector-red {
	    	display: none;
	    }

  		&:after {
	  		content: "";
	  		display: inline-block;
	  		background: url('/img/icons/arrow-right--black.svg');
		    width: 15px;
		    height: 8px;
		    background-size: 15px 8px;
		    margin-left: 7px;
		    background-repeat: no-repeat;
  		}

  		&:hover {
	  		.region-selector-black {
		    	display: none;
		    }

		    .region-selector-red {
		    	display: block;
		    }

	    	&:after {
		  		background: url('/img/icons/arrow-right--red.svg');
		  		background-repeat: no-repeat;
			    width: 8px;
			    height: 15px;
			    background-size: 8px 15px;
			    transform: rotate(90deg);
		      margin-left: 11px;
	  		}
	    }
  	}

  	&-toggle[aria-expanded="true"] {
  		.region-selector-black {
	    	display: none;
	    }

	    .region-selector-red {
	    	display: block;
	    }

  		&:after {
	  		background: url('/img/icons/arrow-right--red.svg');
	  		background-repeat: no-repeat;
		    width: 8px;
		    height: 15px;
		    background-size: 8px 15px;
	      margin-left: 11px;
	  		transform: rotate(270deg);
  	    transition: transform 0.5s;
  		}
  	}

  	&-menu {
  		top: 50px;
  		right: -17px;
  		position: absolute;
	    background: map-get($palette, white);
	    width: 230px;
	    box-sizing: border-box;
			box-shadow: -2px 4px 4px rgba(0, 0, 0, 0.25);

  		@include respond(lg) {
  			right: 0;
		    top: 70px;
  		}
  	}

  	&-item {
	    @include font($size: 13px, $weight: 300, $height: 23px, $spacing: 0.025em, $colour: map-get($palette, black));
  		text-decoration: none;
  		padding: 7px 17px;
  		height: 100%;
	    width: 100%;
	    display: flex;
	    align-items: center;
	    justify-content: space-between;

	    &:hover {
	    	color: map-get($palette, red);
	    	font-weight: 700;

	    	&:after {
	    		background: url('/img/icons/arrow-right--red.svg');
		  		background-repeat: no-repeat;
			    width: 8px;
			    height: 20px;
			    background-size: 8px 15px;
			    background-position: 0px 5px;
			    transform: rotate(90deg);
	    	}
	    }

  		&:after {
  			content: "";
	  		display: inline-block;
	  		background: url('/img/icons/arrow-right--black.svg');
		    width: 15px;
		    height: 8px;
		    background-size: 15px 8px;
		    margin-left: 7px;
		    vertical-align: unset;
		    background-repeat: no-repeat;
  		}
  	}

  	&-item[aria-expanded="true"] {
  		color: map-get($palette, red);
    	font-weight: 700;

    	&:after {
    		background: url('/img/icons/arrow-right--red.svg');
	  		background-repeat: no-repeat;
		    width: 8px;
		    height: 20px;
		    background-size: 8px 15px;
		    background-position: 0px 0px;
		    transform: rotate(270deg);
    	}
  	}

  	&-menu > li:not(:first-of-type)  {
  		border-top: 1px solid map-get($palette, medium-grey);
  	}

  	&-element {
  		@include font($size: 13px, $weight: 700, $height: 23px, $spacing: 0.025em, $colour: map-get($palette, black));
  		text-decoration: none;
  		padding: 0 17px;
  		display: block;

  		&:hover {
	    	color: map-get($palette, black);
	    }
  	}

  	&-submenu > li:first-of-type a {
  		margin-top: -4px;
  	}

  	&-submenu > li:last-of-type a {
  		margin-bottom: 7px;
  	}
  }

	// Bootstrap navbar overrides
	.dropdown-menu {
		position: absolute!important;
		width: 100%;
		border-radius: 0;
		border: none;
		margin-top: 0!important;
    top: 90px;
    display: block;
 		left: -100%;
 		transition: left 0.5s;
		background-color: map-get($palette, white);

    @include respond(lg) {
    	padding-top: 0!important;
	    padding-bottom: 0;
	    min-height: 521px;
    }

    a {
    	text-decoration: none;

    	&:hover {
    		color: inherit;
    	}
    }
	}

	.dropdown-menu.show {
  	position: absolute!important;
    height: calc(100vh - 80px);
    padding-top: 25px;
    left: 0;
    z-index: 5;

    @include respond(lg) {
    	height: unset;
    	min-height: 521px;
    }
  }

  .dropdown-menu.show:not(.header__dropdown):not(.header__level3 ) {
    @include respond(lg) {
	  	left: 25%!important;
  	}
  }

	.navbar-toggler {
		padding: 0;
		width: 45px;

		&:focus {
			box-shadow: none;
		}
	}

	.navbar-toggler[aria-expanded="false"] {
    .navbar-toggler-icon {
      background-image: url(/img/icons/menu.svg);
      width: 45px;
      height: 33px;
    }
  }

  .navbar-toggler[aria-expanded="true"] {
    .navbar-toggler-icon {
      background-image: url(/img/icons/exit.svg);
      width: 33px;
      height: 33px;
      background-size: 33px 33px;
    }
  }

  .navbar {
  	padding: 0;
  	position: unset;
  }

  .navbar-nav {
	  padding-top: 35px;
	  height: calc(100vh - 80px);

	  @include respond(lg) {
	  	height: unset;
	    padding: 18px 0 0;
	  }
  }

  .nav-item.dropdown {
  	position: unset;
  }

  .nav-item.nav-item__active {
  	.nav-link {
	  	@include respond(lg) {
		  	border-bottom: 4px solid map-get($palette, red);
	  	}
  	}
  }

  .nav-item.dropdown:not(:last-child) {
	  @include respond(lg) {
	  	margin-right: 59px;
  	}
  }

  .nav-link.dropdown-toggle {
    @include font($size: 19px, $weight: 700, $height: 25.2px, $spacing: 0.015em, $colour: map-get($palette, black));
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 9px 0;
    transition: all 0.5s;

    @include respond(lg) {
    	font-size: 18px;
    	padding-bottom: 23px;
	    border-bottom: 4px solid map-get($palette, white);
    }

    &:after {
	    @include respond(lg) {
	    	transform: none;
		    margin-left: 7px;
	    }
    }

    &:hover {
	    @include respond(lg) {
	    	color: map-get($palette, red);
    	}

    	&:after {
		    @include respond(lg) {
			    mask: url('/img/icons/arrow-right--black.svg')!important;
			    mask-size: 15px 8px;
			    width: 15px;
			    height: 9px;
			    margin-left: 0;
			    vertical-align: unset;
			    mask-repeat: no-repeat;
			    background-color: map-get($palette, red);
			    background: #F6323E;
			    margin-left: 7px;
			  }
	    }
    }

    &.show {
	    @include respond(lg) {
	    	color: map-get($palette, red);
	    }

	    &:after {
		    @include respond(lg) {
		    	transform: rotate(270deg);
		    	background: url('/img/icons/arrow-right--red.svg');
		    	background-size: 16px 15px;
			    width: 17px;
			    height: 14px;
    	    transition: transform 0.5s;
		    }
	    }

	    &:hover {
	    	 &:after {
			    @include respond(lg) {
			    	mask: unset!important;
			    }
		    }
	    }
    }
  }

  .dropdown-item {
    @include font($size: 19px, $weight: 700, $height: 25.2px, $spacing: 0.015em, $colour: map-get($palette, black));
	  padding-left: 0;
	  margin-top: 5px;

	  a {
	  	color: map-get($palette, black);
	  }
  }

  .dropdown-item:active {
  	background-color: unset;
  }

  .dropdown-item:hover,
  .dropdown-item:focus {
  	background-color: unset;
  }

  .dropdown-toggle {
    @include font($size: 19px, $weight: 300, $height: 25.2px, $spacing: 0.013em, $colour: map-get($palette, black));
  	display: flex;
  	align-items: center;
  	justify-content: space-between;

  	&:after {
  		border: none;
  		background: url('/img/icons/arrow-right--black.svg');
  		transform: rotate(-90deg);
	    width: 15px;
	    height: 8px;
	    background-size: 15px 8px;
	    margin-left: 0;
	    vertical-align: unset;
	    background-repeat: no-repeat!important;
  	}
  }

  .dropdown-menu,
  .dropdown-submenu,
  .dropdown-subSubmenu {
  	background-color: map-get($palette, white);
  }

  &__helperbar {
  	position: absolute;
  	bottom: 0;
  	z-index: 10;
  	width: 100%;

  	@include respond(lg) {
	  	position: fixed;
	  	right: -340px;
	  	top: 220px;
	  	left: unset;
	  	bottom: unset;
	    height: fit-content;
	    display: flex;
	    width: fit-content;
	    transition: right 0.5s;
  	}

  	&--active-bar {
	  	@include respond(lg) {
	  		right: 0;
	  	}
  	}

  	.nav-tabs {
  		border-bottom: none;
  		z-index: 100;
	    position: relative;

	  	@include respond(lg) {
		    flex-direction: column;
		    margin-top: 100px;
	  	}
  	}

  	.nav-tabs .nav-link:hover,
  	.nav-tabs .nav-link:focus {
  		border: none;
	    isolation: unset;
  	}

  	&-mobileMenuTab {
  		cursor: pointer;

  		.header__helperbar-icon {
  			mask: url('/img/icons/menu.svg');
  			mask-repeat: no-repeat;
  			mask-size: 32px 25px;
  		}
  	}

  	#search-tab {
  		.header__helperbar-icon {
  			mask: url('/img/icons/search.svg');
  			mask-repeat: no-repeat;
  			mask-size: 25px 25px;
  			mask-position: center;
  		}
  	}

  	#contact-tab {
  		.header__helperbar-icon {
  			mask: url('/img/icons/phone.svg');
  			mask-repeat: no-repeat;
  			mask-size: 25px 25px;
  			mask-position: center;
  		}
  	}

  	&-tab,
  	&-tab.nav-link {
  		background: map-get($palette, teal);
  		color: map-get($palette, white);
			border-radius: 2px 2px 0px 0px;
			width: 60px;
			height: 60px;
	    text-align: center;
	    padding: 13px 0 0;
	    display: flex;
	    align-items: center;
	    flex-direction: column;
	    justify-content: space-between;
	    border: none;

	  	@include respond(lg) {
		    border-radius: 2px 0px 0px 2px;
	    }

	    &:hover {
		  	@include respond(lg) {
		    	box-shadow: -5px 0px 3px rgba(0, 0, 0, 0.2);
		  	}
	    }
  	}

  	&-tab.nav-link {
  		margin-left: 11px;
  		margin-bottom: 0;

	  	@include respond(lg) {
	  		margin-left: 0;
	  		margin-bottom: 5px;
	  	}
  	}

  	&-icon {
  		height: 25px;
  		width: 32px;
  		background-color: map-get($palette, white);
  	}

  	&-label {
	    @include font($size: 13px, $weight: 700, $height: 19px, $spacing: 0.025em, $colour: map-get($palette, inherit));
  	}

  	&-tab.nav-link.active,
  	&-tab.active {
  		background: map-get($palette, white);
  		box-shadow: 0px -4px 3px rgba(0, 0, 0, 0.12);
  		color: map-get($palette, black);

  		.header__helperbar-icon {
	  		background-color: map-get($palette, black);

	  		@include respond(lg) {
	  			mask: url('/img/icons/close-button.svg')!important;
	  			mask-repeat: no-repeat!important;
	  			mask-size: 15px 15px;
	  			mask-position: center!important;
		  	}
  		}

  		@include respond(lg) {
	    	box-shadow: -5px 0px 3px rgba(0, 0, 0, 0.2);
	  	}
  	}

  	.tab-content {
  		display: none;
  		position: absolute;
  		z-index: 20;
	    bottom: -100vh;
	    width: 100%;
  		background-color: map-get($palette, white);
	    left: calc(-1 * var(--bs-gutter-x, .95rem));
	    transition: bottom 0.5s;

	    @include respond(lg) {
	    	display: block;
	    	position: unset;
				border-radius: 8px 0px 0px 8px;
		    width: 340px;
		    height: 465px;
		    z-index: 150;
	    }

	    &.header__helperbar--active {
	    	bottom: 0;
	    }
  	}

  	.tab-pane {
  		width: 100%;
  		padding-top: 15px;
	    padding-right: var(--bs-gutter-x, .95rem);
	    padding-left: var(--bs-gutter-x, .95rem);
	    margin-right: auto;
	    margin-left: auto;

  		@include respond(lg) {
  			padding: 19px 36px 22px 29px;
  			box-shadow: -2px 4px 4px rgba(0, 0, 0, 0.25);
  			border-radius: 8px 0px 0px 8px;
  			height: 100%;
  		}

  		h4 {
		    @include font($size: 19px, $weight: 700, $height: 23px, $spacing: 0.013em, $colour: map-get($palette, black));
		    margin-bottom: 0;

	  		@include respond(lg) {
			    font-size: 23px;
					line-height: 28px;
	  		}
  		}

  		h5 {
		    @include font($size: 18px, $weight: 700, $height: 22px, $spacing: 0.015em, $colour: map-get($palette, black));

		    @include respond(lg) {
			    font-size: 19px;
					line-height: 23px;
	  		}

  			&.header__helperbar-contact-country {
  				margin-top: 37px;

  				@include respond(lg) {
				    margin-top: 17px;
		  		}
  			}
  		}

  		p {
		    @include font($size: 16px, $weight: 300, $height: 24px, $spacing: 0.015em, $colour: map-get($palette, black));

		    &.header__helperbar-contact-number {
		    	font-weight: 400;
		    	margin-top: 10px;

		  		@include respond(lg) {
			    	font-weight: 300;
		  		}

		    	a {
		    		color: map-get($palette, red);
		    		text-decoration: none;

		    		&:hover {
			    		color: map-get($palette, redtwo);
		    		}
		    	}
		    }
  		}
  	}

  	&-contact-address {
  		p {
  			margin-bottom: 21px;

  			@include respond(lg) {
	  			margin-bottom: 12px;
  			}
  		}
  	}

  	&-contact-buttons {
  		display: flex;
  		flex-direction: column;

  		.button {
		    width: fit-content;

		    &:last-of-type {
		    	margin-top: 10px;
		    }
  		}
  	}

  	&-search {
  		display: flex;
  		aling-items: center;
  		justify-content: space-between;

  		input {
  			width: 100%;
  			margin-top: 9px;
  			background-color: rgba(153, 152, 153, 0.1);;
				border-top: 0.5px solid map-get($palette, grey);
				border-bottom: 0.5px solid map-get($palette, grey);
				border-left: 0.5px solid map-get($palette, grey);
				border-right: none;
				border-radius: 4px 0 0 4px;
				height: 50px;

				&:focus{
			    outline: none;
				}
  		}

  		button {
  			appearance: none;
  			background-color: rgba(153, 152, 153, 0.1);
				height: 50px;
				width: 42px;
				margin-top: 9px;
  			border-top: 0.5px solid map-get($palette, grey);
				border-bottom: 0.5px solid map-get($palette, grey);
				border-right: 0.5px solid map-get($palette, grey);
				border-left: none;
				border-radius: 0 4px 4px 0;
				background-image: url('/img/icons/search.svg');
				background-repeat: no-repeat;
				background-position: 0 11px;
  		}

  		&-popular {
	  		margin-top: 12px;
	  		margin-bottom: 14px;

	  		@include respond(lg) {
	  			margin-bottom: 7px;
	  		}
	  	}
  	}

  	&-search-list {
  		margin-bottom: 25px;

  		@include respond(lg) {
  			margin-bottom: 20px;
  		}

			li {
				padding: 12px 0;
		    border-top: 1px solid map-get($palette, medium-grey);

		    @include respond(lg) {
	  			padding: 8px 0;
	  		}

		    &:last-child {
			    border-bottom: 1px solid map-get($palette, medium-grey);
		    }

				a {
			    @include font($size: 16px, $weight: 300, $height: 24px, $spacing: 0.015em, $colour: map-get($palette, black));
					text-decoration: none;

					&:hover {
						color: map-get($palette, black);
					}
				}
			}
  	}

  	h6 {
	    @include font($size: 13px, $weight: 700, $height: 16px, $spacing: 0.1em, $colour: map-get($palette, teal));
	    text-transform: uppercase;
  	}

  	&-advanced-search--industry {
	  	display: flex;
	    align-items: center;

	    p {
	    	margin: 0;
	    }

	    .selectric-wrapper {
	    	margin-left: 16px;
	    }
  	}

  	&-advanced-search--solution {
	  	display: flex;
	    align-items: center;
	    flex-wrap: wrap;
	    margin-top: 7px;

			p {
				margin-bottom: 0;
				width: 100%;
			}

			.selectric-wrapper {
	    	margin-top: 9px;
	    }

	    .button.button--right-icon-arrow {
	    	margin-top: 9px;
  	    padding-right: 35px;
		    margin-left: 10px;
		    height: 42px;

		    @include respond(lg) {
	  			height: 40px;
			    padding-right: 23px;
	  		}

	  		&:after {
		  		@include respond(lg) {
				    right: 6px;
			    }
	  		}
	    }
  	}

  	.selectric {
    	border: 1px solid map-get($palette, medium-grey);
	    box-sizing: border-box;
	    border-radius: 3px;
	    background: map-get($palette, white);
	    width: 211px;
	    height: 42px;

	    @include respond(lg) {
  			height: 40px;
  			width: 193px;
  		}

	    .label {
	    	font-weight: 300;
				font-size: 16px;
				color: map-get($palette, grey-pass);
	    }

	    .button {
		    background: map-get($palette, white);
		    margin-right: 0;

		    &:after {
		    	border: none;
		  		background: url('/img/icons/arrow-right--black.svg');
		    	background-repeat: no-repeat;
		    	background-size: 15px 18px;
			    width: 15px;
			    height: 14px;
		    }

        &:hover {
          box-shadow: none;
        }
	    }
    }
  }

  // sticky nav
  &--sticky {
  	position: fixed;
  	width: 100%;
  	top: 0;
    z-index: 10;

  	.header__topLevel-wrapper {
  		@include respond(lg) {
	  		height: 0;
		    overflow: hidden;
	    }
  	}

  	.header__navbar-logo {
  		@include respond(lg) {
	  		width: 42%;
	    }

	    @include respond(xxl) {
	  		width: 52%;
	    }
  	}

  	.nav-link.dropdown-toggle {
  		@include respond(lg) {
	  		font-size: 13px;
  		}
  	}
  }

  &--sticky-open {
  	.header__topLevel-wrapper {
  		@include respond(lg) {
	  		height: 100px;
	  		overflow: initial;
	    }
  	}

  	.header__navbar-logo {
  		@include respond(lg) {
	  		width: 0;
	    }
  	}

  	.nav-link.dropdown-toggle {
  		@include respond(lg) {
	  		font-size: 18px;
  		}
  	}
  }
}
