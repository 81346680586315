.footer {
	background-color: map-get($palette, black);
	color: map-get($palette, white);
	padding-top: 49px;
	padding-bottom: 52px;

	@include respond(md) {
		padding-top: 56px;
		padding-bottom: 20px;
	}

	a {
		color: map-get($palette, white);
		text-decoration: none;
	}

	&__logo {
		width: 243px;
    margin: 0 auto;

		@include respond(md) {
			width: 176px;
	    margin: 0;
		}

		@include respond(lg) {
			width: 243px;
		}

		img {
			width: 100%;
		}
	}

	&__link-group {
		text-align: center;
    margin-top: 52px;

		@include respond(md) {
			text-align: left;
			margin-top: 0;
    }

    &--social {
    	text-align: center;
	    margin-top: 56px;

	    @include respond(md) {
				text-align: left;
		    margin-top: 32px;
	    }

	    @include respond(lg) {
		    margin-top: 0;
	    }
    }

    &--position {
    	@include respond(lg) {
		    width: fit-content;
		    position: absolute;
		    right: -28px;
	    }

	    @include respond(xl) {
		    right: -4px;
	    }
    }

    &-title {
    	font-weight: 300;
			font-size: 22px;
			text-decoration: none;

			@include respond(md) {
				text-align: left;
				font-size: 16px;
	    }
    }

    &-list {
    	margin-top: 50px;
    	padding-left: 0;

    	@include respond(md) {
	    	margin-top: 23px;
	    }

    	a {
    		font-size: 22px;
    		font-weight: 500;
    		line-height: 147%;

    		@include respond(md) {
					font-size: 16px;
		    }
    	}
    }
	}

	&__social-icons {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 24px;

		@include respond(lg) {
			max-width: 177px;
		}

		a {
			width: 30px;

			img {
				width: 100%;
			}
		}
	}

	&__bottom {
		margin-top: 63px;
    text-align: center;

		@include respond(md) {
			border-top: 1px solid map-get($palette, light-grey-transparent);
			padding-top: 20px;
			text-align: left;
		}

		.container {
			@include respond(md) {
				display: flex;
				align-items: center;
				justify-content: space-between;
				flex-direction: row-reverse;
			}
		}

    &-links {
    	a {
    		font-weight: 500;
		    letter-spacing: 0.015em;
    	}
    }
	}

	&__copyright {
		margin-top: 12px;

		@include respond(md) {
			margin-top: 0;
		}

		br {
			@include respond(md) {
				display: none;
			}
		}

		.wysiwyg {
			p {
				color: map-get($palette, white);
				font-weight: normal;

				@include respond(md) {
					margin-bottom: 0;
				}
			}
		}
	}
}


