.exploreFurther {
  $block: &;
  padding: 23px 15px 56px 15px;
  max-width: 375px;
  margin: 0 auto;

  @include respond(md) {
    max-width: 674px;
  }

  @include respond(xl) {
    max-width: 1080px;
  }

  &__title {
    text-align: center;
    h4 {
      @include font($size: 23px, $weight: 500, $spacing: 0.023em, $height: 28px, $colour: inherit);
      display: inline-block;
      margin-bottom: 20px;

      @include respond(xl) {
        @include font($size: 23px, $weight: 500, $spacing: 0.023em, $height: 32px, $colour: inherit);
      }


    }
    span {
      @include font($size: 27px, $weight: 300, $spacing: 0.023em, $height: 32px, $colour: inherit);
    }
  }

  &__col {
    margin-top: 60px;

    h6 {
      @include font($size: 16px, $weight: 700, $spacing: 0.025em, $height: 19px, $colour: inherit);
      text-transform: uppercase;
    }

    @include respond(md) {
      margin-top: 48px;
    }

    @include respond(lg) {
      margin-top: 55px;
    }
  }

  &__no-title {
    margin-top: 0;
  }

  &__link {
    @include font($size: 18px, $weight: 700, $spacing: 0.015em, $height: 22px, $colour: inherit);
    padding: 24px;
    margin: 8px 0;
    width: 100%;

    @include respond(lg) {
      @include font($size: 19px, $weight: 700, $spacing: 0.015em, $height: 23px, $colour: inherit);
    }
  }

  &__col1 {
    @include respond(md) {
      padding-right: 30px;
    }

    @include respond(xl) {
      padding-right: 75px;
    }

  }

  &__col2 {
    @include respond(md) {
      padding-left: 30px;
    }

    @include respond(xl) {
      padding-left: 75px;
    }
  }
}
