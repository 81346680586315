.promoPanelRow {
    $block: &;

    position: relative;
    margin-bottom: 68px;
    margin-top: 35px;

    @include respond(md) {
        margin-bottom: 103px;
    }


    .order-0 &__text-column,
    .order-0 &__background {
        @include respond(sm) {
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
            overflow: hidden;
        }


        @include respond(md) {
            border-top-right-radius: 0;
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
        }
    }

    .order-1 &__text-column,
    .order-1 &__background {
        @include respond(sm) {
            border-bottom-left-radius: 3px;
            border-bottom-right-radius: 3px;
            overflow: hidden;
        }


        overflow: hidden;

        @include respond(md) {
            border-bottom-left-radius: 0;
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
        }
    }

    &__text-column {
        padding-top: 33px;
        padding-bottom: 45px;
        padding-left: 20px;
        padding-right: 20px;

        @include respond(lg) {
            padding-top: 50px;
            padding-bottom: 50px;
            padding-left: 60px;
            padding-right: 60px;
        }


        &--teal {
            color: map-get($palette, white);
            background-image: map-get($gradients, grey-transparent-overlay);
            background-color: map-get($palette, teal);
        }

        &--greyblue {
            color: map-get($palette, white);
            background-image: map-get($gradients, grey-transparent-overlay);
            background-color: map-get($palette, greyblue);
        }
    }

    &__featured-subtitle {
        padding-top: 10px;
        margin-bottom: 26px;
        border-top-color: map-get($palette, red);
        border-top-style: solid;
        border-top-width: 6px;
        display: inline-block;

        @include font($size: 15px, $weight: 400, $height: 17.58px, $spacing: 0.025em, $colour: inherit);

        text-transform: uppercase;
    }

    &__title {
        @include font($size: 27px, $weight: 500, $height: 31.64px, $colour: inherit);

        margin-bottom: 8px;
    }

    &__body {
        @include font($size: 15px, $weight: 300, $height: 24px, $spacing: 0.015em, $colour: inherit);

        margin-top: 14px;
        margin-bottom: 26px;

        &--large {
            margin-top: 0;

            @include font($size: 27px, $weight: 400, $height: 32.37px, $colour: inherit);
            @include respond(lg) {
                @include font($size: 33px, $weight: 400, $height: 39.56px, $colour: inherit);
            }
        }
    }

    &__background-col {
        height: 287px;
        position: relative;

        @include respond(md) {
            height: auto;
        }
    }

    &__background {
        height: 100%;
        width: 100%;
        display: block;

        //This forces the image to stick to the height of the other column in the grid.
        @include respond(md) {
            width: 100%;
            height: 1px;
            min-height: 100%;
            display: block;
        }
    }

    &__background-content {
        object-fit: cover;
        object-position: center center;
        width: 100%;
        height: 100%;
    }

    &__product {
        position: absolute;
        right: 0;
        bottom: -68px;
        max-height: 287px;
        max-width: 50%;

        @include respond(md) {
            max-height: 100%;
        }


        &--left {
            right: unset;
            left: 0;
        }

        &--center {
            right: unset;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &--hero {
        margin-top: 0;

        .order-0 &__text-column,
        .order-0 &__background {
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
            overflow: hidden;

            @include respond(md) {
                border-top-right-radius: 0;
                border-top-left-radius: 3px;
                border-bottom-left-radius: 3px;
            }
        }

        .order-1 &__text-column,
        .order-1 &__background {
            border-bottom-left-radius: 3px;
            border-bottom-right-radius: 3px;
            overflow: hidden;

            @include respond(md) {
                border-bottom-left-radius: 0;
                border-top-right-radius: 3px;
                border-bottom-right-radius: 3px;
            }
        }
    }

    &--teal {
        color: map-get($palette, white);
        background-image: map-get($gradients, grey-transparent-overlay);
        background-color: map-get($palette, teal);

        & #{$block}__text-column {
            color: map-get($palette, white);
        }
    }

    &--greyblue {
        color: map-get($palette, white);
        background-image: map-get($gradients, grey-transparent-overlay);
        background-color: map-get($palette, greyblue);

        & #{$block}__text-column {
            color: map-get($palette, white);
        }
    }

    &--full-width {
        padding-top: 77px;
        padding-bottom: 76px;
        margin-top: 0;
        margin-bottom: 0;

        @include respond(md) {
            padding-top: 58px;
        }


        & #{$block}__text-column {
            padding-left: 0;
            padding-right: 0;
            padding-top: 17px;
            padding-bottom: 0;
            border-color: map-get($palette, red);
            border-style: solid;
            border-top-width: 6px;
            margin-top: 45px;

            @include respond(md) {
                border-top-width: 0;
                border-left-width: 6px;
                padding-left: 31px;
                padding-top: 0;
            }


            @include respond(lg) {
                margin-top: 54px;
                margin-left: 30px;
            }


            &--without-border {
                border-color: map-get($palette, transparent);
            }
        }

        & #{$block}__text-column + #{$block}__text-column {
            padding-top: 0;
            margin-top: 45px;

            @include respond(md) {
                margin-top: 38px;
            }
        }

        & #{$block}__featured-subtitle {
            border-top: none;
            padding-top: 0;
        }

        & #{$block}__title {
            @include font($size: 23px, $weight: 700, $height: 27.57px, $spacing: 0.015em, $colour: inherit);

            margin-bottom: 12px;
        }

        & #{$block}__body {
            @include font($size: 16px, $weight: 300, $height: 24px, $spacing: 0.015em, $colour: inherit);

            margin-bottom: 0;

            @include respond(md) {
                margin-bottom: 26px;
            }
        }

        & #{$block}__background {
            overflow: auto;
            box-shadow: 16.7134px 18.592px 80px rgba(0, 0, 0, 0.07), 10.8328px 12.0504px 46.8519px rgba(0, 0, 0, 0.05), 6.43776px 7.16136px 25.4815px rgba(0, 0, 0, 0.04), 3.34268px 3.7184px 13px rgba(0, 0, 0, 0.04), 1.36183px 1.5149px 6.51852px rgba(0, 0, 0, 0.03), 0.309508px 0.344296px 3.14815px rgba(0, 0, 0, 0.02);
            border-radius: 3px;
            position: relative;

            @include respond(md) {
                min-height: 392px;
            }


            @include respond(lg) {
                min-height: 428px;
            }


            &:after {
                content: " ";
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                display: block;
                background-image: linear-gradient(328.84deg, rgba(46, 41, 37, 0.59) 15.38%, rgba(46, 41, 37, 0) 43.68%);
            }
        }
    }

    &--with-product {
        $standard-text-top-padding: 33px;

        :not(#{$block}__background-col).order-1 #{$block}__text-column {
            padding-top: 68px + $standard-text-top-padding;

            @include respond(md) {
                padding-top: $standard-text-top-padding;
            }
        }
    }
}
