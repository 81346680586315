// All the varients that refer to Heading <X>/Small/<WEIGHT> are versions shown __below__ lg viewports,
// They dont include the 'small' in their class name.

// All the varients that refer to Heading <X>/large/<WEIGHT> are versions shown in lg viewports and above.

// The default styling for each heading is medium, eg the name in figma is Heading <X>/<viewport version>/medium, 
// here the class name for these would just be the tag h<x>

//Some examples from figma naming to css classes;
// figma: H1/Heading 1/Small/Medium -> h1
// figma: H1/Heading 1/Large/Medium -> h1
// figma: H2/Heading 2/Large/Medium -> h2
// figma: H2/Heading 2/Large/Light -> .h2--light
// figma: H3/Heading 3/Large/Bold  .h3--bold
// figma: H5/Heading 5/Large/Bold  .h5--bold
// figma: H5/Heading 5/Small/Regular .h5--regular

/* h1 */
h1 {
    font-family: "Swiss",sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 110%;
    margin-bottom: 15px;
}

/* .h1--regular */
.h1--regular {
    font-family: "Swiss",sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    line-height: 110%;
}

/* .h1--light */
.h1--light {
    font-family: "Swiss",sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 40px;
    line-height: 110%;
}

/* .h1--bold */
.h1--bold {
    font-family: "Swiss",sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 110%;
}

/* .h2--bold */
.h2--bold {
    font-family: "Swiss",sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 33px;
    line-height: 40px;
}

/* h2 */
h2 {
    font-family: "Swiss",sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 33px;
    line-height: 40px;
}

/* .h2--regular */
.h2--regular {
    font-family: "Swiss",sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 33px;
    line-height: 40px;
}

/* .h2--light */
.h2--light {
    font-family: "Swiss",sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 33px;
    line-height: 40px;
}

/* .h3--bold */
.h3--bold {
    font-family: "Swiss",sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 27px;
    line-height: 32px;
}

/* h3 */
h3 {
    font-family: "Swiss",sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 27px;
    line-height: 32px;
}

/* .h3--regular */
.h3--regular {
    font-family: "Swiss",sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 27px;
    line-height: 32px;
}

/* .h3--light */
.h3--light {
    font-family: "Swiss",sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 27px;
    line-height: 32px;
}

/* .h4--bold */
.h4--bold {
    font-family: "Swiss",sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    line-height: 28px;
    letter-spacing: 0.015em;
}

/* h4 */
h4 {
    font-family: "Swiss",sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 28px;
    margin-bottom: 14px;
}

/* .h4--regular */
.h4--regular {
    font-family: "Swiss",sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 23px;
    line-height: 28px;
}

/* .h4--light */
.h4--light {
    font-family: "Swiss",sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 23px;
    line-height: 28px;
}

/* .h5--bold */
.h5--bold {
    font-family: "Swiss",sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    line-height: 23px;
    letter-spacing: 0.013em;
}

/* h5 */
h5 {
    font-family: "Swiss",sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 23px;
}

/* .h5--regular */
.h5--regular {
    font-family: "Swiss",sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 19px;
    line-height: 23px;
}

/* .h5--light */
.h5--light {
    font-family: "Swiss",sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 19px;
    line-height: 23px;
}

/* .h6--bold */
.h6--bold {
    font-family: "Swiss",sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.015em;
}

/* h6 */
h6 {
    font-family: "Swiss",sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.015em;
}

/* .h6--regular */
.h6--regular {
    font-family: "Swiss",sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.015em;
}

/* .h6--light */
.h6--light {
    font-family: "Swiss",sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.015em;
}

// All the varients that refer to Heading <X>/large/<WEIGHT> are versions shown in lg viewports and above.
@include respond(lg) {
    /* h1 */
    h1 {
        font-family: "Swiss",sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 58px;
        line-height: 110%;
    }

    /* .h1--bold */
    .h1--bold {
        font-family: "Swiss",sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 58px;
        line-height: 110%;
    }

    /* .h1--regular */
    .h1--regular {
        font-family: "Swiss",sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 58px;
        line-height: 110%;
    }

    /* .h1--light */
    .h1--light {
        font-family: "Swiss",sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 58px;
        line-height: 110%;
    }

    /* .h2--bold */
    .h2--bold {
        font-family: "Swiss",sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 40px;
        line-height: 48px;
    }

    /* h2 */
    h2 {
        font-family: "Swiss",sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 40px;
        line-height: 48px;
    }

    /* .h2--regular */
    .h2--regular {
        font-family: "Swiss",sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 40px;
        line-height: 48px;
    }

    /* .h2--light */
    .h2--light {
        font-family: "Swiss",sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 40px;
        line-height: 48px;
    }

    /* .h3--bold */
    .h3--bold {
        font-family: "Swiss",sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 33px;
        line-height: 40px;
    }

    /* h3 */
    h3 {
        font-family: "Swiss",sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 33px;
        line-height: 40px;
    }

    /* .h3--regular */
    .h3--regular {
        font-family: "Swiss",sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 33px;
        line-height: 40px;
    }

    /* .h3--light */
    .h3--light {
        font-family: "Swiss",sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 33px;
        line-height: 40px;
    }

    /* .h4--bold */
    .h4--bold {
        font-family: "Swiss",sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 27px;
        line-height: 32px;
    }

    /* h4 */
    h4 {
        font-family: "Swiss",sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 27px;
        line-height: 32px;
    }

    /* .h4--regular */
    .h4--regular {
        font-family: "Swiss",sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 27px;
        line-height: 32px;
    }

    /* .h4--light */
    .h4--light {
        font-family: "Swiss",sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 27px;
        line-height: 32px;
    }

    /* .h5--bold */
    .h5--bold {
        font-family: "Swiss",sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 23px;
        line-height: 28px;
    }

    /* h5 */
    h5 {
        font-family: "Swiss",sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 23px;
        line-height: 28px;
    }

    /* .h5--regular */
    .h5--regular {
        font-family: "Swiss",sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 23px;
        line-height: 28px;
    }

    /* .h5--light */
    .h5--light {
        font-family: "Swiss",sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 23px;
        line-height: 28px;
    }

    /* h6 */
    h6 {
        font-family: "Swiss",sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 19px;
        line-height: 23px;
        letter-spacing: 0.015em;
    }

    /* .h6--regular */
    .h6--regular {
        font-family: "Swiss",sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 19px;
        line-height: 23px;
        letter-spacing: 0.015em;
    }

    /* .h6--light */
    .h6--light {
        font-family: "Swiss",sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 19px;
        line-height: 150%;
        letter-spacing: 0.015em;
    }

    /* .h6--bold */
    .h6--bold {
        font-family: "Swiss",sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 19px;
        line-height: 23px;
        letter-spacing: 0.015em;
    }
}
