.faq {
  padding: 85px 0;

  @include respond(lg) {
    padding: 132px 0;
  }

  &__title {
    text-align: center;
    padding-bottom: 24px;
  }
}

