.contactFormRow {
  margin: 50px 0;

  @include respond(md) {
    margin: 70px 0;
  }

  .kentico-form {
    h5 {
      padding-bottom: 40px;
    }
  }
}
