//COLOURS
$palette: (
    //primary 
    red: #F6323E,
    redtwo: #E31521,
    grey: #999899,
    nearlyblack: #2E2925,
    blue: #003CA6,
    
    //secondary 
    darkblue: #212844,
    green: #5E7260,
    teal: #007480,
    purple: #634B77,

    //accent
    orange: #EB6851,
    lightblue:#9CB6D2,
    cream: #D7D1C4,
    greyblue: #35647D,
    pastelBlue: #d6e3f1,

    white: #ffffff, 
    black: #000000, 
    light-grey: #FAFAFA,
    lightish-grey: #AAAAAA,
    medium-grey: #DDDDDD,
    darker-grey: #c1c1c1,
    grey-pass: #6E6E6E,
    input-grey: #E2E8F0,

    transparent: rgba(255, 255, 255, 0),
    white-slightly-transparent: rgba(255, 255, 255, 0.9),
    grey-half-transparent: rgba(110, 110, 110, 0.5),
    grey-transparent: rgba(153, 152, 153, 0.5),
    grey-half-transparent-border-2: rgba(153, 152, 153, 0.5),
    grey-half-transparent-border: rgba(110, 110, 110, 0.3),
    grey-half-transparent-bg: rgba(110, 110, 110, 0.1),
    black-overlay-transparent: rgba(0, 0, 0, 0.4),
    black-half-transparent: rgba(0, 0, 0, 0.5),
    grey-mostly-transparent: rgba(0, 0, 0, 0.2),
    light-grey-transparent: rgba(250, 250, 250, 0.1),
    dark-grey-transparent: rgba(48, 48, 48, 0.75)
);

$gradients:(
    light-blue: linear-gradient(0deg, rgba(53, 100, 125, 0.2) 0%, rgba(0, 0, 0, 0) 100%, map-get($palette, pastelBlue)),
    white-transparent: linear-gradient(0deg, map-get($palette, white) 0%, map-get($palette, transparent) 83.02%),
    grey-transparent-overlay: linear-gradient(0deg, map-get($palette, grey-mostly-transparent) 0%, map-get($palette, transparent) 100%), 
    soft-fade-white-to-grey-ltr: linear-gradient(
        90deg,
        #fffefc 0%,
        #fffefc 6.25%,
        #fefdfb 12.5%,
        #fcfbf9 18.75%,
        #faf9f8 25%,
        #f8f6f5 31.25%,
        #f5f4f3 37.5%,
        #f2f0f0 43.75%,
        #efeded 50%,
        #ebeaea 56.25%,
        #e8e6e7 62.5%,
        #e5e4e5 68.75%,
        #e3e1e2 75%,
        #e1dfe1 81.25%,
        #dfdddf 87.5%,
        #dedcde 93.75%,
        #dedcde 100%
    )
);

//FONT 
$font-family: 'Swiss', sans-serif;
$font-family--alt: 'Swiss', sans-serif;
//$font-family--condensed: 'Swiss Condensed', sans-serif;
//$font-family--extended: 'Swiss Extended', sans-serif;
//$font-family--outline: 'Swiss Outline', sans-serif;

$font-family--icon: 'iconfont', sans-serif;

//ICON FONT
$icons: ();

$zindex-under-content: -1;  
