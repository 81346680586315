.contactFormIntro {
  margin-top: 70px;

  @include respond(lg) {
    margin-top: 104px;
    position: relative;
  }

  .wysiwyg {
    img {
      width: 100%;
      height: auto;
      border-radius: 3px 0 0 3px;

      @include respond(lg) {
        position: absolute;
        top: 0;
        right: 0;
        max-width: 510px;
        max-height: 380px;
      }

      @include respond(xl) {
        max-width: 594px;
      }
    }
  }

}
