// #region Main Swiss font 
@font-face {
    font-family: 'Swiss';
    src: url('../fonts/Swiss721BT-Thin.woff2') format('woff2'),
        url('../fonts/Swiss721BT-Thin.woff') format('woff'),
        url('../fonts/Swiss721BT-Thin.svg#Swiss721BT-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Swiss';
    src: url('../fonts/Swiss721BT-ThinItalic.woff2') format('woff2'),
        url('../fonts/Swiss721BT-ThinItalic.woff') format('woff'),
        url('../fonts/Swiss721BT-ThinItalic.svg#Swiss721BT-ThinItalic') format('svg');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Swiss';
    src: url('../fonts/Swiss721BT-Light.woff2') format('woff2'),
        url('../fonts/Swiss721BT-Light.woff') format('woff'),
        url('../fonts/Swiss721BT-Light.svg#Swiss721BT-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Swiss';
    src: url('../fonts/Swiss721BT-LightItalic.woff2') format('woff2'),
        url('../fonts/Swiss721BT-LightItalic.woff') format('woff'),
        url('../fonts/Swiss721BT-LightItalic.svg#Swiss721BT-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}



@font-face {
    font-family: 'Swiss';
    src: url('../fonts/Swiss721BT-Regular.woff2') format('woff2'),
        url('../fonts/Swiss721BT-Regular.woff') format('woff'),
        url('../fonts/Swiss721BT-Regular.svg#Swiss721BT-Regular') format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Swiss';
    src: url('../fonts/Swiss721BT-Italic.woff2') format('woff2'),
        url('../fonts/Swiss721BT-Italic.woff') format('woff'),
        url('../fonts/Swiss721BT-Italic.svg#Swiss721BT-Italic') format('svg');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}


@font-face {
    font-family: 'Swiss';
    src: url('../fonts/Swiss721BT-Medium.woff2') format('woff2'),
        url('../fonts/Swiss721BT-Medium.woff') format('woff'),
        url('../fonts/Swiss721BT-Medium.svg#Swiss721BT-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Swiss';
    src: url('../fonts/Swiss721BT-MediumItalic.woff2') format('woff2'),
        url('../fonts/Swiss721BT-MediumItalic.woff') format('woff'),
        url('../fonts/Swiss721BT-MediumItalic.svg#Swiss721BT-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}


@font-face {
    font-family: 'Swiss';
    src: url('../fonts/Swiss721BT-Bold.woff2') format('woff2'),
        url('../fonts/Swiss721BT-Bold.woff') format('woff'),
        url('../fonts/Swiss721BT-Bold.svg#Swiss721BT-Bold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Swiss';
    src: url('../fonts/Swiss721BT-BoldItalic.woff2') format('woff2'),
        url('../fonts/Swiss721BT-BoldItalic.woff') format('woff'),
        url('../fonts/Swiss721BT-BoldItalic.svg#Swiss721BT-BoldItalic') format('svg');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Swiss';
    src: url('../fonts/Swiss721BT-Heavy.woff2') format('woff2'),
        url('../fonts/Swiss721BT-Heavy.woff') format('woff'),
        url('../fonts/Swiss721BT-Heavy.svg#Swiss721BT-Heavy') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Swiss';
    src: url('../fonts/Swiss721BT-HeavyItalic.woff2') format('woff2'),
        url('../fonts/Swiss721BT-HeavyItalic.woff') format('woff'),
        url('../fonts/Swiss721BT-HeavyItalic.svg#Swiss721BT-HeavyItalic') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}
//#endregion Main Swiss font 

// #region Condensed alternate Swiss font
// @font-face {
//     font-family: 'Swiss Condensed';
//     src: url('../fonts/Swiss721BT-LightCondensed.woff2') format('woff2'),
//         url('../fonts/Swiss721BT-LightCondensed.woff') format('woff'),
//         url('../fonts/Swiss721BT-LightCondensed.svg#Swiss721BT-LightCondensed') format('svg');
//     font-weight: 300;
//     font-style: normal;
//     font-display: swap;
// }
// @font-face {
//     font-family: 'Swiss Condensed';
//     src: url('../fonts/Swiss721BT-LightCondensedItalic.woff2') format('woff2'),
//         url('../fonts/Swiss721BT-LightCondensedItalic.woff') format('woff'),
//         url('../fonts/Swiss721BT-LightCondensedItalic.svg#Swiss721BT-LightCondensedItalic') format('svg');
//     font-weight: 300;
//     font-style: italic;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Swiss Condensed';
//     src: url('../fonts/Swiss721BT-Condensed.woff2') format('woff2'),
//         url('../fonts/Swiss721BT-Condensed.woff') format('woff'),
//         url('../fonts/Swiss721BT-Condensed.svg#Swiss721BT-Condensed') format('svg');
//     font-weight: 400;
//     font-style: normal;
//     font-display: swap;
// }
// @font-face {
//     font-family: 'Swiss Condensed';
//     src: url('../fonts/Swiss721BT-ItalicCondensed.woff2') format('woff2'),
//         url('../fonts/Swiss721BT-ItalicCondensed.woff') format('woff'),
//         url('../fonts/Swiss721BT-ItalicCondensed.svg#Swiss721BT-ItalicCondensed') format('svg');
//     font-weight: 400;
//     font-style: italic;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Swiss Condensed';
//     src: url('../fonts/Swiss721BT-BoldCondensed.woff2') format('woff2'),
//         url('../fonts/Swiss721BT-BoldCondensed.woff') format('woff'),
//         url('../fonts/Swiss721BT-BoldCondensed.svg#Swiss721BT-BoldCondensed') format('svg');
//     font-weight: 700;
//     font-style: normal;
//     font-display: swap;
// }
// @font-face {
//     font-family: 'Swiss Condensed';
//     src: url('../fonts/Swiss721BT-BoldCondensedItalic.woff2') format('woff2'),
//         url('../fonts/Swiss721BT-BoldCondensedItalic.woff') format('woff'),
//         url('../fonts/Swiss721BT-BoldCondensedItalic.svg#Swiss721BT-BoldCondensedItalic') format('svg');
//     font-weight: 700;
//     font-style: italic;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Swiss Condensed';
//     src: url('../fonts/Swiss721BT-BlackCondensed.woff2') format('woff2'),
//         url('../fonts/Swiss721BT-BlackCondensed.woff') format('woff'),
//         url('../fonts/Swiss721BT-BlackCondensed.svg#Swiss721BT-BlackCondensed') format('svg');
//     font-weight: 900;
//     font-style: normal;
//     font-display: swap;
// }
// @font-face {
//     font-family: 'Swiss Condensed';
//     src: url('../fonts/Swiss721BT-BlackCondensedItalic.woff2') format('woff2'),
//         url('../fonts/Swiss721BT-BlackCondensedItalic.woff') format('woff'),
//         url('../fonts/Swiss721BT-BlackCondensedItalic.svg#Swiss721BT-BlackCondensedItalic') format('svg');
//     font-weight: 900;
//     font-style: italic;
//     font-display: swap;
// }
// #endregion Condensed Swiss font 

// #region Extended Swiss font 
// @font-face {
//     font-family: 'Swiss Extended';
//     src: url('../fonts/Swiss721BT-LightExtended.woff2') format('woff2'),
//         url('../fonts/Swiss721BT-LightExtended.woff') format('woff'),
//         url('../fonts/Swiss721BT-LightExtended.svg#Swiss721BT-LightExtended') format('svg');
//     font-weight: 300;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Swiss Extended';
//     src: url('../fonts/Swiss721BT-Extended.woff2') format('woff2'),
//         url('../fonts/Swiss721BT-Extended.woff') format('woff'),
//         url('../fonts/Swiss721BT-Extended.svg#Swiss721BT-Extended') format('svg');
//     font-weight: 400;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Swiss Extended';
//     src: url('../fonts/Swiss721BT-BoldExtended.woff2') format('woff2'),
//         url('../fonts/Swiss721BT-BoldExtended.woff') format('woff'),
//         url('../fonts/Swiss721BT-BoldExtended.svg#Swiss721BT-BoldExtended') format('svg');
//     font-weight: 700;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Swiss Extended';
//     src: url('../fonts/Swiss721BT-BlackExtended.woff2') format('woff2'),
//         url('../fonts/Swiss721BT-BlackExtended.woff') format('woff'),
//         url('../fonts/Swiss721BT-BlackExtended.svg#Swiss721BT-BlackExtended') format('svg');
//     font-weight: 900;
//     font-style: normal;
//     font-display: swap;
// }
// #endregion END Extended Swiss

// #region Outline Swiss 
// @font-face {
//     font-family: 'Swiss Outline';
//     src: url('../fonts/Swiss721BT-BoldOutline.woff2') format('woff2'),
//         url('../fonts/Swiss721BT-BoldOutline.woff') format('woff'),
//         url('../fonts/Swiss721BT-BoldOutline.svg#Swiss721BT-BoldOutline') format('svg');
//     font-weight: 700;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Swiss Outline';
//     src: url('../fonts/Swiss721BT-BlackOutline.woff2') format('woff2'),
//         url('../fonts/Swiss721BT-BlackOutline.woff') format('woff'),
//         url('../fonts/Swiss721BT-BlackOutline.svg#Swiss721BT-BlackOutline') format('svg');
//     font-weight: 900;
//     font-style: normal;
//     font-display: swap;
// }
// #endregion Outline Swiss 

// #region Swiss Black 2 
// @font-face {
//     font-family: 'Swiss Black 2';
//     src: url('../fonts/Swiss721BT-BlackNo2.woff2') format('woff2'),
//         url('../fonts/Swiss721BT-BlackNo2.woff') format('woff'),
//         url('../fonts/Swiss721BT-BlackNo2.svg#Swiss721BT-BlackNo2') format('svg');
//     font-weight: 900;
//     font-style: normal;
//     font-display: swap;
// }
// #endregion Swiss Black 2 

// #region Swiss Condensed Outline 
// @font-face {
//     font-family: 'Swiss Condensed Outline';
//     src: url('../fonts/Swiss721BT-BoldCondensedOutline.woff2') format('woff2'),
//         url('../fonts/Swiss721BT-BoldCondensedOutline.woff') format('woff'),
//         url('../fonts/Swiss721BT-BoldCondensedOutline.svg#Swiss721BT-BoldCondensedOutline') format('svg');
//     font-weight: 900;
//     font-style: normal;
//     font-display: swap;
// }
// #endregion Swiss Condensed Outline 