.ctaPanel {
  $block: &;

  height: auto;
  width: 100%;
  position: relative;

  h3 {
    margin: 0;
    padding-bottom: 9px;
    font-weight: 700;
  }

  h6 {
    @include font($size: 16px, $weight: 500, $spacing: inherit, $height: 24px, $colour: inherit);
    padding-bottom: 14px;
  }

  span {
    @include font($size: 16px, $weight: 300, $spacing: 0.015em, $height: 24px, $colour: inherit);
  }

  &--spacer {
    margin-top: 5px;
    margin-bottom: 14px;
  }

  &--link {
    width: 100%;
    z-index: 2;
    text-decoration: none;
  }

  &--background {
    height: 100%;
    width: 100%;
    display: block;
    position: absolute;
  }

  &--background-content {
    object-fit: cover;
    object-position: center center;
    width: 100%;
    max-height: 100%;
  }

  &--btn {
    display: block;
    width: 161px;
    margin: 26px auto 0 auto;
    padding-right: 34px;

    &--reduce-pad {
        @include respond(xl) {
          margin: 10px auto;
      }
    }
  }

  hr {
    margin: 0;
    background-color: transparent;
  }

  &--blue {
    background-color: map-get($palette, greyblue);
    background-image: linear-gradient(0deg, rgba(0,0,0,0.2) 0%, transparent 100%);

    h3, h6, span, p {
      color: white;
    }

    &--background::after {
      content: "";
      display: block;
      width: 100%;
      height: inherit;
      position: absolute;
      top: 0;
      left: 0;
      background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, transparent 100%);
      background-color: rgba(53, 100, 125, 0.94);
    }

    &--frame {
      z-index: 2;
      margin: 0;
      width: 335px;
      padding-top: 40px;
      padding-bottom: 40px;

      @include respond(md) {
        width: 761px;
        padding-top: 80px;
        padding-bottom: 80px;
      }

      @include respond(xl) {
        width: 1040px;
      }
    }
  }

  &--teal {
    background-color: map-get($palette, teal);
    h3, h6, span, p {
      color: white;
    }

    &--background::after {
      content: "";
      display: block;
      width: 100%;
      height: inherit;
      position: absolute;
      top: 0;
      left: 0;
      background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, transparent 100%);
      background-color: rgba(0, 116, 128, 0.94);
    }

    &--frame {
      z-index: 2;
      margin: 0;
      width: 375px;
      padding-top: 60px;
      padding-bottom: 64px;

      @include respond(md) {
        width: 761px;
      }

      @include respond(xl) {
        width: 757px;
        padding-top: 68px;
        padding-bottom: 68px;
      }
    }
  }

  &--grey {
    background-color: map-get($palette, light-grey);
    h3, h6, span, p {
      color: black;
    }

    &--background::after {
      content: "";
      display: block;
      width: 100%;
      height: inherit;
      position: absolute;
      top: 0;
      left: 0;
      background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, transparent 100%);
      background-color: rgba(250, 250, 250, 0.94);
    }

    &--frame {
      z-index: 2;
      margin: 0;
      width: 375px;
      padding-top: 92px;
      padding-bottom: 92px;

      @include respond(md) {
        width: 761px;
        padding-bottom: 62px;
      }

      @include respond(xl) {
        width: 840px;
        padding-top: 72px;
        padding-bottom: 71px;
      }
    }
  }

}
