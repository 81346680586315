$components: ("articles", "download", "caseStudy", "news");

@each $component in $components {
  .#{$component}FullList {
    padding-bottom: 47px;

    @include respond (lg) {
      padding-bottom: 69px;
    }

    .tabbed-panel__tabs-wrapper {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      scrollbar-width: none;
      flex-wrap: nowrap;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .tabbed-panel .tabbed-panel__body {
      padding: 0;
    }

    .tabbed-panel__tabs {
      overflow-x: unset!important;
      width: fit-content;
      margin: 0 auto;
    }

    &__featured-item {
      padding: 40px 0 0 0;

      @include respond(md) {
        padding: 70px 0;
        background-color: map-get($palette, light-grey);
        border-bottom: 1px solid map-get($palette, grey-half-transparent-border);
      }

      & > .container {
        @media (max-width: 767px) {
          padding: 0;
          max-width: unset;
        }
      }
    }

    &__featured-item-background {
      height: 100%;
      width: 100%;
      display: block;
    }

    &__featured-item-image {
      object-fit: cover;
      object-position: center center;
      width: 100%;
      height: 100%;
      min-height: 276px;

      @include respond(lg) {
        min-height: 350px;
      }

      @include respond(xl) {
        min-height: 430px;
      }
    }

    &__featured-item-content {
      border-bottom: 20px solid map-get($palette, red);

      @include respond(md) {
        border-left: 20px solid map-get($palette, red);
        border-bottom: none;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
      }
    }

    &__featured-item-text {
      margin: 51px 0;

      @include respond(md) {
        margin: 30px;
        flex-shrink: 1;
        width: 80%;
      }

      @include respond(lg) {
        margin: 45px;
      }

      @include respond(xl) {
        margin: 60px;
      }

      & > .container {
        @include respond(md) {
          padding: 0;
          max-width: unset;
        }
      }
    }

    &__featured-item-title {
      color: map-get($palette, red);
      font-size: 15px;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 26px;
    }

    &__featured-row {
      padding-top: 52px;

      @include respond (lg) {
        padding-top: 48px;
      }

      .featuredCard {
        margin-bottom: 33px;

        @include respond (lg) {
          margin-bottom: 48px;
        }
      }
    }

    &__latest {
      .contentCard {
        margin-bottom: 39px;

        @include respond (lg) {
          margin-bottom: 52px;
        }
      }
    }

    &__more {
      button {
        margin-top: 16px;

        @include respond (lg) {
          margin-top: 28px;
        }
      }
    }
  }
}
