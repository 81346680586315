.heroRow {
	$block: &;

	position: relative;
	height: 845px;

	@include respond(md) {
		height: 480px;
	}

	@include respond(lg) {
		border-color: map-get($palette, red);
		border-style: solid;
		border-left-width: 20px;
    height: 570px;
	}

	&__background {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: block;
		z-index: $zindex-under-content;

		& #{$block}__background-content {
			object-fit: cover;
			object-position: center center;
			width: 100%;
			height: 100%;
		}

		&--video {
			&::after {
				content: ' ';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background-color: map-get($palette, black-overlay-transparent);
			}
		}
	}

	&__text-padding {
		padding-left: 16px;
		padding-right: 16px;
		padding-top: 48px;
		padding-bottom: 48px;

		@include respond(md) {
			padding-right: 0;
			padding-bottom: 45px;
		}

		@include respond(lg) {
			padding-left: 50px;
			padding-right: 48px;
			padding-top: 22px;
			padding-bottom: 44px;
		}
	}

  &--left-text {
    height: 838px;
    @include respond(md) {
      height: 570px;
    }

    #{$block}__textPanel {
      right: auto;
      border-bottom: 20px solid map-get($palette, red);

      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      width: calc(100% - 20px);

      @include respond(lg) {
        width: 50%;
        border-bottom: 0;
      }
    }

    #{$block}__text-padding {
      padding-left: 16px;
      padding-right: 16px;
      padding-top: 48px;
      padding-bottom: 48px;

      @include respond(md) {
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 45px;
      }

      @include respond(lg) {
        padding-left: 70px;
        padding-right: 48px;
        padding-top: 22px;
        padding-bottom: 44px;
      }
    }

  }

	&__textPanel {
		position: absolute;
		top: 390px;
		right: 16px;

		background-color: map-get($palette, white);
		color: map-get($palette, black);

		border-color: map-get($palette, red);
		border-style: solid;
		border-bottom-width: 20px;

		min-height: 275px;

		@include respond(md) {
			top: 160px;
			right: 256px;

			min-height: 250px;
		}

		@include respond(lg) {
			top: 162px;
			right: auto;
			min-height: 245px;

			border-bottom-width: 0;
		}

		&-container:before {
			content: ' ';
			left: 50%;
			height: 100%;
			display: inline-block;
			position: absolute;
			transform: translateX(-50%);
			width: 100%;
			bottom: 0;
			z-index: -1;

			background-color: map-get($palette, white-slightly-transparent);
			background-image: map-get($gradients, white-transparent);
			border-radius: 3px 3px 0px 0px;

			@include respond(md) {
				width: 94%;
			}
			@include respond(lg) {
				width: 72%;
			}
		}

		&--middle-text {
			border: none;
			left: 16px;
			right: 16px;
			bottom: 0;
			top: auto;

			background-image: map-get($gradients, white-transparent);
			background-color: map-get($palette, white-slightly-transparent);
			border-radius: 3px 3px 0px 0px;

			#{$block}__text-padding {
				padding-left: 30px;
				padding-right: 30px;
				padding-top: 26px;
				padding-bottom: 10px;

				@include respond(md) {
					padding-left: 45px;
					padding-right: 45px;
					padding-top: 45px;
				}

				@include respond(xl) {
					padding-left: 93px;
					padding-right: 93px;
				}
			}

			#{$block}__title {
				margin-bottom: 15px;
			}

			@include respond(md) {
				left: 50%;
				transform: translateX(-50%);
				right: auto;
				width: 764px;
			}

			@include respond(xl) {
				width: 1041px;
			}
		}

		&--article {
			#{$block}__category {
				@include respond(lg) {
					visibility: hidden;
				}
			}
		}

		&--centered-transparent {
			top: 115px;
			left: auto;
			right: auto;

			border: none;
			background: none;
			color: map-get($palette, white);
			text-align: center;

			@include respond(md) {
				top: 50%;
				transform: translateY(-50%);
			}

			@include respond(lg) {
				width: 100%;
			}

			#{$block}__text-padding {
				@include respond(md) {
					padding-left: 100px;
					padding-right: 100px;
				}
			}

			#{$block}__title {
				font-size: 50px;
				font-weight: 600;
				line-height: 55px;

				margin-bottom: 5px;

				@include respond(md) {
					margin-bottom: 20px;
				}
				@include respond(lg) {
					font-size: 72px;
					line-height: 79.2px;

					margin-bottom: 15px;
				}

				@include respond(xl) {
					margin-left: 197px;
					margin-right: 197px;
				}
			}

			#{$block}__body {
				font-size: 16px;
				line-height: 24px;

				@include respond(md) {
					font-size: 18px;
					line-height: 27px;
				}
				@include respond(xl) {
					margin-left: 356px;
					margin-right: 356px;
				}
			}
		}
	}

	&__breadcrumbs {
		padding-left: 0;
		margin-bottom: 15px;
	}

	&__breadcrumb-item {
		color: map-get($palette, black);
		display: inline-block;
		margin: 0;
		@include font(
			$size: 16px,
			$weight: 400,
			$height: 24px,
			$spacing: 0.015em,
			$colour: inherit
		);
		&::after {
			content: ' ';
			background-image: url('/img/icons/Side Arrow.png');
			background-repeat: no-repeat;
			width: 6px;
			height: 10px;
			background-position: center center;
			margin-left: 10px;
			margin-right: 10px;
			display: inline-block;
		}

    &:last-of-type {
      &::after {
        display: none;
      }
    }

		&--current {
			font-weight: 700;
			color: map-get($palette, greyblue)!important;
		}
	}

	&__breadcrumb-link {
		color: map-get($palette, black);
		text-decoration: none;

    &:hover {
      color: map-get($palette, black);
    }
	}

	&__category {
		@include font(
			$size: 16px,
			$weight: 500,
			$spacing: 0.025em,
			$height: 18.75px,
			$colour: inherit
		);
		margin-top: 14px;
		margin-bottom: 15px;
		text-transform: uppercase;
		display: block;

		@include respond(md) {
			margin-top: 0;
		}
	}

	&__title {
		@include font($size: 40px, $weight: 500, $height: 44px, $colour: inherit);

		@include respond(lg) {
			font-size: 58px;
			line-height: 63.8px;
		}
	}

	&__subtitle {
		@include font($size: 33px, $weight: 300, $height: 39px, $colour: inherit);

		@include respond(lg) {
			font-size: 40px;
			line-height: 46.8px;
		}
	}

	&__body {
		@include font(
			$size: 18px,
			$weight: 300,
			$height: 27px,
			$spacing: 0.015em,
			$colour: inherit
		);
	}

	&__date {
		@include font(
			$size: 16px,
			$weight: 500,
			$spacing: 0.015em,
			$height: 24px,
			$colour: inherit
		);
		margin-top: 15px;
		display: block;

		@include respond(md) {
			line-height: 0.75;
		}
	}

	&__logo {
		width: 100%;
		height: 170px;
		padding-left: 53.5px;
		padding-right: 53.5px;
		padding-top: 40px;
		padding-bottom: 40px;

		object-fit: contain;
		background-color: map-get($palette, white);
		border-bottom: 1px map-get($palette, grey-half-transparent) solid;
	}

	&--small-image {
		height: auto;
		display: flex;
		flex-direction: column-reverse;

		@include respond(md) {
			flex-direction: row;
			height: 363px;
		}

		@include respond(lg) {
			flex-direction: row;
			height: 412px;
		}

		#{$block}__background {
			height: 321px;
			position: static;
			flex-shrink: 0;

			@include respond(md) {
				width: 364px;
				height: 100%;
			}
			@include respond(lg) {
				width: 412px;
			}
		}

		#{$block}__textPanel {
			top: auto;
			right: auto;
			position: static;
			border-bottom: 0;
			border-top-width: 20px;

			@include respond(md) {
				border-right-width: 20px;
				border-top-width: 0;
				overflow-y: hidden;
			}
			@include respond(lg) {
				border-right-width: 0;
				flex-grow: 1;
			}
		}

		#{$block}__text-padding {
			padding-top: 30px;
			padding-bottom: 40px;

			@include respond(md) {
				padding-top: 58.5px;
        padding-left: 34.5px;
			}

			@include respond(xl) {
				padding-right: 220px;
        padding-left: 50px;
			}
		}

		#{$block}__breadcrumbs {
			@include respond(md) {
				margin-bottom: 50.5px;
			}
		}
	}

  &--usage-info {
    @include respond(md) {
      height: 432px;
    }

    @include respond(lg) {
      height: 467px;
    }

    #{$block}__usage-info {
      padding: 9px 16px 40px 16px;

      @include respond(md) {
        padding: 9px 34.5px 30px 34.5px;
      }

      @include respond(xl) {
        padding: 9px 0 19px 50px;
      }
    }

    #{$block}__usage-info-title {
      color: map-get($palette, white);
      background-color: map-get($palette, teal);
      padding: 5px 10px;
      width: fit-content;
      margin-bottom: 15px;
    }

    #{$block}__usage-info-tags {
      padding: 0;
      display: flex;
      align-items: center;

      li {
        padding-right: 8px;

        &:not(:first-of-type) {
          padding-left: 8px;
        }

        &:not(:last-of-type) {
          border-right: 1px solid map-get($palette, black);
        }
      }
    }
  }

	&--middle-text {
		border: none;
	}

	&--case-study {
    margin-bottom: 35px;
		#{$block}__textPanel {
			@include respond(md) {
				display: flex;
				flex-direction: row-reverse;

				min-height: 232px;
			}
		}

		#{$block}__logo {
			@include respond(md) {
				width: 371px;
				height: auto;
				object-position: top;

				padding-top: 31px;
				padding-left: 62px;
				padding-right: 62px;

				border-bottom: none;
				border-left: 1px map-get($palette, grey-half-transparent) solid;
			}
		}
	}

	&--article {
		#{$block}__text-padding {
			padding-left: 0;
			padding-right: 0;

			@include respond(md) {
				padding-left: 0;
				padding-right: 0;
			}

			@include respond(xl) {
				padding-left: 0;
				padding-right: 0;
			}
		}

		#{$block}__textPanel--middle-text {
			background-color: transparent;
			background-image: none;
			left: 16px;
			@include respond(md) {
				width: 100%;
				left: 50%;
			}

			&--middle-text {
				background-image: unset;
				background-color: transparent;
			}
		}
	}

	&--video {
		height: 767px;

		@include respond(md) {
			height: 632px;
		}

		@include respond(lg) {
			height: 614px;
			border-left: none;
		}
	}

	&--overlay-row {
		margin-bottom: -141px;
		@include respond(md) {
			margin-bottom: -51px;
		}
	}

	&--article + .textRow .textRow__wysiwyg:before {
		content: ' ';
		display: block;
		background-color: map-get($palette, orange);
		height: 6px;

		margin-left: 30px;
		margin-right: 30px;
		margin-top: 26px;
		margin-bottom: 30px;

		@include respond(md) {
			width: 144px;
			display: inline-block;

			margin-left: 0;
			margin-right: 0;
			margin-top: 36px;
			margin-bottom: 30px;
		}

		@include respond(lg) {
			margin-top: 0;
		}
	}
}
