.navComponent {
  $block: &;

  height: 55px;
  padding: 4px;
  margin-bottom: 65px;
  margin-top: 30px;
  background-color: map-get($palette, light-grey);

  a {
    @extend .title--large--bold;
    color: black;
    text-decoration: none;
    border: none;
    text-transform: uppercase;
  }

  .button {
    margin-right: 0;
  }
}
