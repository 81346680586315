.circleCard {
  $block: &;

  display: block;
  position: relative;
  text-decoration: none;
  border-radius: 3px;
  height: 372px;
  margin-bottom: 52px;
  box-shadow: 9px 0px 80px rgba(0, 0, 0, 0.07), 5.83333px 0px 46.8519px rgba(0, 0, 0, 0.0531481),
  3.46667px 0px 25.4815px rgba(0, 0, 0, 0.0425185), 1.8px 0px 13px rgba(0, 0, 0, 0.035),
  0.733333px 0px 6.51852px rgba(0, 0, 0, 0.0274815), 0.166667px 0px 3.14815px rgba(0, 0, 0, 0.0168519);

  &:hover {
    color: inherit;

    // arrow circle
    & #{$block}__title {
      &:before {
        background-color: map-get($palette, light-grey);
      }
    }
  }

  &__text {
    margin: 20px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 3;
  }

  &__title {
    $circle-width: 46px;
    $circle-height: 46px;

    padding-right: $circle-width;
    margin-bottom: 0;
    position: absolute;
    bottom: 0;
    width: 100%;

    @include font($size: 18px, $weight: 700, $height: 21.58px, $spacing: 0.015em, $colour: inherit);
    @include respond(md){
      font-size: 23px;
      line-height: 27.57px;
      letter-spacing: unset;
    }

    &:before {
      position: absolute;
      content: " ";
      display: block;
      border-radius: 100%;
      width: $circle-width;
      height: $circle-height;
      right: 0;
      bottom: 0;
      background-color: map-get($palette, white);
    }

    &:after {
      position: absolute;
      right: $circle-width * 0.5;
      bottom: $circle-height * 0.5;
      transform: translateX(50%) translateY(50%);

      content: " ";
      display: block;
      width: 9px;
      height: 16px;

      background-position: center center;
      background-repeat: no-repeat;
      background-image: url(/img/icons/arrow-right--nearlyblack.png);
      background-image: none, url(/img/icons/arrow-right--nearlyblack.svg);
    }
  }

  &__subtitle {
    &--orange {
      color: map-get($palette, orange);
    }

    &--teal {
      color: map-get($palette, teal);
    }

    &--grey {
      color: map-get($palette, grey-pass);
    }

    &--green {
      color: map-get($palette, green);
    }

    &--purple {
      color: map-get($palette, purple);
    }
  }

  &__secondaryTitle {
    margin-top: 6px;

    &--green {
      color: map-get($palette, green);
    }

    &--purple {
      color: map-get($palette, purple);
    }
  }

  &--caseStudy {
    color: map-get($palette, black);
    background-color: map-get($palette, transparent);
    background: map-get($gradients, soft-fade-white-to-grey-ltr);
    height: 208px;
    margin-bottom: 28px;

    @include respond(md){
      height: 300px;
    }
  }

  &--industryGuide {
    color: map-get($palette, greyblue);
    background-color: map-get($palette, pastelBlue)!important;
    background: map-get($gradients, light-blue);

    &:hover {
      color: map-get($palette, greyblue);
    }
  }

  &--paper {
    color: map-get($palette, black);
    background-color: map-get($palette, transparent);
    background: map-get($gradients, soft-fade-white-to-grey-ltr);

    & #{$block}__title {
      padding-right: 68px;
    }
  }

  &--brochure {
    color: map-get($palette, white);
    // background: linear-gradient(map-get($palette, black-overlay-transparent)), url(/img/hero-background--mobile.png);
    background-repeat: no-repeat!important;
    background-position: center!important;
    background-size: cover!important;
    position: relative;

    &:after {
      content:"";
      position:absolute;
      top:0;
      left:0;
      border-radius: 3px;
      height:100%;
      width:100%;
      background: linear-gradient(map-get($palette, black-overlay-transparent));
    }

    &:hover {
      color: map-get($palette, white);
    }

    & #{$block}__title {
      padding-right: 68px;
    }

    &.brochure--download {
      & #{$block}__title {
        bottom: 30px;

        &:before {
          bottom: -30px;
        }

        &:after {
          bottom: -6px;
        }
      }
    }

    & #{$block}__file {
      position: absolute;
      bottom: 0;
    }
  }
}

.serviceCard {
  color: map-get($palette, white);
  background-color: map-get($palette, greyblue);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(0deg, map-get($palette, greyblue), map-get($palette, greyblue)), map-get($palette, light-grey);
  display: block;
  text-decoration: none;
  padding: 45px 20px;
  border-radius: 3px;

  &:hover {
    color: map-get($palette, white);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(0deg, map-get($palette, greyblue), map-get($palette, greyblue)), map-get($palette, light-grey);
    box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.15);
  }

  @include respond(lg) {
    padding: 45px;
  }

  &__subtitle {
    margin-bottom: 14px;
  }

  &__title {
    margin-bottom: 14px;
  }

  &__text {
    p {
      margin-bottom: 20px;
    }

    p:last-of-type {
      margin-bottom: 14px;
    }
  }
}

.productCard {
  $block: &;

  display: block;
  margin-bottom: 60px;
  color: map-get($palette, black);
  background-color: map-get($palette, transparent);
  margin-bottom: 60px;

  @include respond(md) {
    margin-bottom: 30px;
  }

  @include respond(lg) {
    margin-bottom: 0;
  }

  &__background {
    height: 100%;
    width: 100%;
    display: block;
  }

  &__background-content {
    object-fit: cover;
    object-position: center center;
    width: 100%;
    height: 100%;
    max-height: 195px;
    border-radius: 3px;

    @include respond(md) {
      max-height: 236px;
    }
  }

  &__text {
    margin: 20px;
  }

  &__summary {
    margin-bottom: 27px;
  }

  &:hover {
    .button:not(.button--grey) {
      text-decoration: none;
      color: map-get($palette, white);
      box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.15);
      background: map-get($palette, redtwo);
    }
  }

  &__usage-info-title {
    color: map-get($palette, white);
    background-color: map-get($palette, teal);
    padding: 5px 10px 5px 20px;
    width: fit-content;
    margin-bottom: 15px;
  }

  &__usage-info-tags {
    padding: 0 0 0 20px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    li {
      padding-right: 8px;
      margin-bottom: 3px;

      &:not(:first-of-type) {
        padding-left: 8px;
      }

      &:not(:last-of-type) {
        border-right: 1px solid map-get($palette, black);
      }
    }
  }

  &--range {
    padding: 15px;
    border: 0.75px solid map-get($palette, lightish-grey);
    box-sizing: border-box;
    border-radius: 3px;

    &:hover {
      box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.12);

      .button.button--grey {
        text-decoration: none;
        color: map-get($palette, grey-pass);
        box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.15);
        background: map-get($palette, transparent);
      }
    }

    & #{$block}__text {
      margin: 24px 0 0 0;
    }

    & #{$block}__buttons {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      flex-direction: column;

      @include respond(xxl) {
        flex-direction: row;
      }
    }

    .button:not(.button--grey) {
      margin-right: 15px;
      margin-bottom: 15px;

      @include respond(xxl) {
        margin-bottom: 0;
        margin-right: 0;
        padding: 12px 29px 12px 16px;

        &:after {
          right: 9px;
        }
      }
    }

    .button--grey {
      margin-right: 0;
    }
  }
}

.contentCard {
  $block: &;

  display: block;
  margin-bottom: 60px;
  color: map-get($palette, black);
  background-color: map-get($palette, transparent);
  margin-bottom: 28px;

  &__background {
    height: 100%;
    width: 100%;
    display: block;
  }

  &__background-content {
    object-fit: cover;
    object-position: center center;
    width: 100%;
    height: 100%;

    max-height: 195px;

    @include respond(md){
      max-height: 236px;
    }
  }

  &__subtitle {
    &--grey {
      color: map-get($palette, grey-pass);
    }
  }

  &__text {
    margin: 0;

    span {
      display: block;
      padding-top: 9px;
      padding-bottom: 16px;
      margin: 0;
    }
  }

  &__text-wrapper {
    border-left: 6px solid map-get($palette, red);
    padding-left: 14px;
  }

  &--default {
    & #{$block}__text-wrapper {
      margin-top: 21px;
    }

    p {
      margin: 14px 0 0;
    }

    & #{$block}__usage-info-title {
      color: map-get($palette, white);
      background-color: map-get($palette, teal);
      padding: 5px 10px;
      width: fit-content;
      margin-bottom: 15px;
    }

    #{$block}__usage-info-tags {
      padding: 0;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      li {
        padding-right: 8px;
        margin-bottom: 3px;

        &:not(:first-of-type) {
          padding-left: 8px;
        }

        &:not(:last-of-type) {
          border-right: 1px solid map-get($palette, black);
        }
      }
    }
  }
}

// utilities
.card--highlight-text {
  @extend %hightlight-child-heading-on-hover;
}
