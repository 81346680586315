.personSummary {
  $block: &;

  h6 {
    @include font($size: 15px, $spacing: 0.025em, $height: 18px, $weight: 700, $colour: map-get($palette, grey-pass));
    margin-bottom: 5px;
  }

  hr {
    margin: 0;
  }

  &--container {
    padding-top: 30px;

    img {
      width: 135px;
      height: 135px;
      object-fit: contain;
    }

    div {
      padding-left: 22px;
    }

    .title {
      @include font($size: 19px, $spacing: 0.015em, $height: 25px, $weight: 700, $colour: map-get($palette, black));
      display: block;
    }

    .text {
      @include font($size: 19px, $spacing: 0.015em, $height: 25px, $weight: 300, $colour: map-get($palette, black));
      display: block;
    }
  }
}
