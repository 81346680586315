.caseStudyCa {
  $block: &;

  height: auto;
  margin: 4px auto;
  position: relative;

  p {
    padding: 0;
    margin: 0;
  }

  .row {
    margin: 0;
  }

  &--orange {
    background: linear-gradient(0deg, rgba(165, 58, 40, 0.2) 0%, rgba(235, 104, 81, 0) 100%),
    linear-gradient(0deg, #EB6851, #EB6851);
    border-radius: 3px;
    color: white;

    &--frame {
      z-index: 2;
      margin: 0;
      width: 100%;
      padding: 35px;

      h2, p {
        padding-top: 13px;
        margin: 0;
      }
    }
  }

  &--teal {
    background-color: map-get($palette, teal);
    border-radius: 3px;
    color: white;

    &--frame {
      z-index: 2;
      margin: 0;
      width: 100%;
      padding: 35px;

      h2, p {
        padding-top: 13px;
        margin: 0;
      }
    }
  }

  &--grey {
    background-color: #F8F8F8;
    border-radius: 3px;

    &--frame {
      z-index: 2;
      margin: 0;
      width: 100%;
      padding: 35px;

      h6 {
        margin: 0;
        padding-bottom: 30px;
      }

      ul {
        list-style-type: disc;
        padding-left: 1.2rem;
        @include font($size: 16px, $weight: 300, $spacing: 0.015em, $height: 24px, $colour: inherit);
        padding-top: 24px;
      }

      .application {
        padding: 0;

        @include respond(md) {
          padding: 0 18px 0 0;
        }
      }

      .solution {
        padding: 24px 0 0 0;

        @include respond(md) {
          padding: 0 0 0 18px;
        }
      }
    }
  }

  &--black {
    background-color: black;
    border-radius: 3px;
    color: white;

    &--frame {
      z-index: 2;
      margin: 0;
      width: 100%;
      padding: 35px;

      h2 {
        padding: 0 0 32px 0;
        margin: 0;
      }

      .text {
        padding: 0;

        @include respond(md) {
          padding: 0 23px 0 0;
        }
      }

      .media {
        padding: 24px 0 0 0;

        @include respond(md) {
          padding: 0 0 0 23px;
        }

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  &--blue {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%), #35647D;
    border-radius: 3px;
    color: white;

    &--frame {
      z-index: 2;
      margin: 0;
      width: 100%;
      padding: 35px;

      .text {
        padding: 0;

        h2 {
          padding: 10px 0;
          margin: 0;
        }

        @include respond(md) {
          padding: 0 23px 0 0;
        }
      }

      .media {
        padding: 24px 0 0 0;

        @include respond(md) {
          padding: 0 0 0 23px;
        }

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }


}
