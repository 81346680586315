.inPageNavigation {
  &--sticky {
    position: fixed;
    width: 100%;
    z-index: 8;
  }

  &__header {
    background-color: map-get($palette, light-grey);
    border-bottom: 1px solid map-get($palette, grey-transparent);
    padding: 34px 0 28px 0;

    @include respond(lg) {
      padding: 40px 0 28px 0;
    }
  }

  &__items {
    border-bottom: 2px solid map-get($palette, red);
    background-color: map-get($palette, white);

    .container {
      @media (max-width: 575px) {
        padding: 0;
        max-width: unset;
      }
    }
  }

  &__items-list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -2px;

    a {
      display: block;
      color: map-get($palette, grey-pass);
      text-decoration: none;
      padding: 4.5px 20px;
      border-right: 1px solid map-get($palette, grey-transparent);
      border-bottom: 1px solid map-get($palette, grey-transparent);

      @include respond(lg) {
        font-size: 18px;
        padding: 14px 20px;
      }

      &:first-of-type {
        @include respond(sm) {
          padding-left: 0;
        }
      }

      &:hover {
        color: map-get($palette, grey-pass);
        text-decoration: none;
      }
    }
  }
}
