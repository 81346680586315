.locationForm {
  $block: &;

  &--backBtn {
    background-color: map-get($map: $palette, $key: grey-pass);
    height: 40px;

    .button {
      border-radius: 0;
      box-shadow: unset;
    }

    .button--white {
      border: 0;
    }
  }

  &--mapContainer {
    position: relative;
    padding-bottom: 55px;

    .map {
      width: 100% !important;
      height: 419px !important;
      margin: 0 auto;
      padding: 0;
    }

    &--mapPopover {
      position: absolute;
      width: 330px;
      z-index: 2;
      background-color: white;
      display: none;
      bottom: 0;
      padding-bottom: 42px;

      @include respond(md) {
        display: block;
      }
    }

    &--mapPopoverMobile {
      display: block;

      @include respond(md) {
        display: none;
      }
    }
  }

  &--leftCol {
    margin: 50px 70px;
  }

  &--padContactDetails {
    padding-left: 10%;
    width: 50%;
    margin: 0 auto;
  }

  &--contactDetailsWrapper {
    margin-left: 60px;
  }

  &--tabbedPanelBorder {
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: map-get($map: $palette, $key: grey-half-transparent-border);
  }

  &--contactForm {
    padding-top: 48px;
    .container {
      max-width: none;
      width: unset;
      padding: 0;
    }

    &--noColor {
      form {
        background-color: transparent;
      }
    }

    .col-xl-8 {
      width: 100%;
    }

    .row {
      --bs-gutter-x: 0;
    }

    &--box {
      max-width: 482px; height: 222px;
      border-radius: 3px;
      box-shadow: 0px 20.0926px 46.8519px rgba(0, 0, 0, 0.0531481), 0px 11.9407px 25.4815px rgba(0, 0, 0, 0.0425185), 0px 6.2px 13px rgba(0, 0, 0, 0.035), 0px 2.52593px 6.51852px rgba(0, 0, 0, 0.0274815), 0px 0.574074px 3.14815px rgba(0, 0, 0, 0.0168519);
      padding: 68px 86px;
      text-align: center;
      margin: 0 auto 48px auto;
      p {
        text-align: center;
        margin-bottom: 23px;

        @include font($size: 18px, $weight: 700, $spacing: inherit, $height: 27px, $colour: inherit);
      }

    }
  }

  &--centerVariant {
    .container {
      max-width: 856px;
    }

    .col-xl-8 {
      width: 100%;
    }
  }
}
