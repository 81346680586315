.downloadRow {
  $block: &;

	margin-top: 69px;
	margin-bottom: 84px;

	@include respond(md) {
		margin-top: 44px;
		margin-bottom: 143px;
	}

  .left-container {
    margin-left: 16px;

    @include respond(md) {
      margin-left: 34px;
    }

    @include respond(xl) {
      margin-left: 68px;
    }
  }

  .pad-top {
    margin-top: 62px;
  }

  &--table {
    margin-top: 69px;
  }

  p {
    margin: 0;
  }

  h2 {
    margin-bottom: 19px;
  }

  .container {
    padding: 0;
  }

  .accordion {
    padding-top: 31px;
    margin-left: 16px;

    @include respond(md) {
      margin-left: 24px;
    }

    @include respond(xl) {
      margin-left: 55px;
    }
  }
}

.downloadList{
	$block: &;

  max-width: 413px;
  margin: 35px auto 50px auto;
  padding: 0 12px;

	&__title {
		@include font($size: 15px, $spacing: 0.025em, $height: 18px, $weight: 700, $colour: map-get($palette, black));
		text-transform: uppercase;

		display: inline-block;
		width: auto;
		margin-bottom: 12px;

		@include respond(md) {
			margin-bottom: 20px;
      margin-top: 0px;
		}

		&::after {
			margin-top: 5px;
			display: block;
			content: " ";
			width: 100%;
			padding-right: 30px;
			box-sizing: content-box;
			height: 6px;
			background-color: map-get($palette, red);
		}
	}

	&__ctaBox {
		$ctaBox-x-padding: 26px;
		padding-top: 39px;
		padding-left: $ctaBox-x-padding;
		padding-right: $ctaBox-x-padding;
		padding-bottom: 39px;

		@include respond(md) {
			padding: 26px;
		}

		& + #{$block}__ctaBox{
			position: relative;
			&:before{
				position: absolute;
				top: 0;
				content: " ";
				display: inline-block;
				width: calc(100% - #{$ctaBox-x-padding}*2);
				height: 1px;
				background-color: map-get($palette, darker-grey);

				@include respond(md){
					max-width: calc(308px - #{$ctaBox-x-padding}*2);
				}
			}
		}

	}

	&__items{
		background-color: map-get($palette, teal);
		border-radius: 3px;

		background-image: map-get($gradients, grey-transparent-overlay);
		background-color: map-get($palette, teal);
		box-shadow: 0px 38px 49px rgba(0, 0, 0, 0.012037), 0px 24.6296px 28.6968px rgba(0, 0, 0, 0.0196296),
			0px 14.637px 15.6074px rgba(0, 0, 0, 0.025), 0px 7.6px 7.9625px rgba(0, 0, 0, 0.0303704),
			0px 3.0963px 3.99259px rgba(0, 0, 0, 0.037963), 0px 0.703704px 1.92824px rgba(0, 0, 0, 0.05);
	}


	&__ctaBox-title {
		@include font($size: 27px, $spacing: 0em, $height: 32px, $weight: 700, $colour: map-get($palette, white));
		margin-bottom: 14px;

		@include respond(md) {
			@include font($size: 33px, $spacing: 0em, $height: 40px, $weight: 700, $colour: map-get($palette, white));
			margin-bottom: 28px;
		}
	}

  &__ctaBox-title_noPad {
    @include font($size: 27px, $spacing: 0em, $height: 32px, $weight: 700, $colour: map-get($palette, white));
    margin-bottom: 0;

    @include respond(md) {
      @include font($size: 33px, $spacing: 0em, $height: 40px, $weight: 700, $colour: map-get($palette, white));
      margin-bottom: 0;
    }
  }

  &__ctaBox-fileInfo {
    @extend .title--large--bold;
    color: white;
    margin-bottom: 14px;
    padding: 0;
    display: block;

    @include respond(md) {
      margin-bottom: 28px;
    }
  }

}
