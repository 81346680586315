$components: ("articles", "download", "insights", "news");

@each $component in $components {
  .#{$component}ListRow {
    color: map-get($palette, black);
    background-color: map-get($palette, white);
    margin-top: 48px;
    padding-bottom: 60px;
    border-bottom: 1px solid map-get($palette, grey-half-transparent-border-2);

    @include respond(md){
      margin-top: 43px;
      padding-bottom: 33px;
    }

    &__title {
      margin-bottom: 32px;
    }

    &__row{
      margin-bottom: 53px;
      @include respond(md){
        margin-bottom: 33px;
      }
    }

    .button {
      text-transform: capitalize;
    }
  }
}

