.image {
  figure {
    width: 100%;
    height: auto;

    img {
      width: 100%;
      border-radius: 3px;
      box-shadow: 0px 38px 38px rgba(0, 0, 0, 0.07), 0px 24.6296px 22.2546px rgba(0, 0, 0, 0.0531481), 0px 14.637px 12.1037px rgba(0, 0, 0, 0.0425185), 0px 7.6px 6.175px rgba(0, 0, 0, 0.035), 0px 3.0963px 3.0963px rgba(0, 0, 0, 0.0274815), 0px 0.703704px 1.49537px rgba(0, 0, 0, 0.0168519);
    }

    figcaption {
      margin: 30px 0;
      @include font($size: 13px, $spacing: 0.025em, $height: 16px, $weight: inherit, $colour: map-get($palette, black));
    }
  }
}
