p {
    font-family: "Swiss",sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.015em;
    font-feature-settings: 'ordn' on;
    margin-bottom: 30px;

    @include respond(lg) {
        margin-bottom: 45px;
    }
}

.body-text--regular {
    font-family: "Swiss",sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.015em;
    font-feature-settings: 'ordn' on;
}

.body-text--light {
    font-family: "Swiss",sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.015em;
    font-feature-settings: 'ordn' on;
}

.body-text--underline {
    font-family: "Swiss",sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.015em;
    text-decoration-line: underline;
    font-feature-settings: 'ordn' on;
}

.body-text--italic {
    font-family: "Swiss",sans-serif;
    font-style: italic;
    font-weight: 300;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.015em;
    font-feature-settings: 'ordn' on;
}

.body-text--bold {
    font-family: "Swiss",sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.015em;
}

.large-body--bold {
    font-family: "Swiss",sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: 0.015em;
}

.large-body--regular {
    font-family: "Swiss",sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: 0.015em;
}

.large-body--light {
    font-family: "Swiss",sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: 0.015em;
}

.large-body--underline {
    font-family: "Swiss",sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: 0.015em;
    text-decoration-line: underline;
}

.large-body--italic {
    font-family: "Swiss",sans-serif;
    font-style: italic;
    font-weight: 300;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: 0.015em;
}

.small-body--bold {
    font-family: "Swiss",sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 150%;
    letter-spacing: 0.025em;
}

.small-body--regular {
    font-family: "Swiss",sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.025em;
}

.small-body--light {
    font-family: "Swiss",sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 150%;
    letter-spacing: 0.025em;
}

.small-body--underline {
    font-family: "Swiss",sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.025em;
    text-decoration-line: underline;
}

.small-body--italic {
    font-family: "Swiss",sans-serif;
    font-style: italic;
    font-weight: 300;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.025em;
}

.title--large--bold {
    font-family: "Swiss",sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.025em;
    text-transform: uppercase;
}

.title--large--light {
    font-family: "Swiss",sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.025em;
    text-transform: uppercase;
}

.title--small--bold {
    font-family: "Swiss",sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
}

.title--small--light {
    font-family: "Swiss",sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
}

blockquote {
    font-family: "Swiss",sans-serif;
    font-style: italic;
    font-weight: bold;
    font-size: 23px;
    line-height: 150%;
}

.pull-quote--large {
    font-family: "Swiss",sans-serif;
    font-style: italic;
    font-weight: bold;
    font-size: 23px;
    line-height: 150%;
}

.pull-quote--small {
    font-family: "Swiss",sans-serif;
    font-style: italic;
    font-weight: bold;
    font-size: 19px;
    line-height: 150%;
}
