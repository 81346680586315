.video {
	margin: 30px 0;

	&__placeholder {
		position: relative;
	}

  &__placeholder-image {
  	border-radius: 3px;
  	object-fit: cover;
  	max-width: unset!important;
    margin: 0!important;
    position: absolute!important;
  }

  &__placeholder-overlay {
  	border-radius: 3px;
  	position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: 2;
  }

  &__placeholder-text {
  	max-width: 50%;
    margin-left: 10px;
    background-color: map-get($palette, white-slightly-transparent);
    padding: 0 10px;
    height: 85%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include respond(sm) {
	  	max-width: 48%;
    }
  }

  &__placeholder-title {
  	text-decoration: underline;
    text-decoration-color: map-get($palette, red);
    text-decoration-thickness: 0.1px;
    text-underline-position: from-font;
    text-transform: capitalize;
    font-size: 18px!important;
    margin-top: -20px;

    @include respond(sm) {
	    font-size: 27px!important;
	    margin-bottom: 30px!important;
      margin-top: 0;
    }

    @include respond(xl) {
    	font-size: 32px!important;
    	margin-bottom: 40px!important;
    }

    @include respond(xxl) {
    	font-size: 47px!important;
    }
  }

  &__placeholder-summary {
    font-size: 15px!important;
    text-transform: capitalize;
    font-weight: 500!important;
    line-height: 1.15!important;
    margin: 0;

    @include respond(sm) {
	    font-size: 23px !important;
    }

    @include respond(xl) {
	    font-size: 25px !important;
	    padding-right: 35%;
    }

    @include respond(xxl) {
    	font-size: 32px!important;
    }
  }

  &__placeholder-playbutton {
  	cursor: pointer;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: map-get($palette, dark-grey-transparent);

    img {
      width: 50px;
      transition: all 0.5s;

      @include respond(lg) {
        width: 84px;
      }

      &:hover {
        width: 60px;

        @include respond(lg) {
          width: 94px;
        }
      }
    }
  }

  &__placeholder-playbutton-text {
    color: map-get($palette, white);
    margin-top: 10px;

    @include respond(lg) {
      margin-top: 15px;
    }
  }

  &__iframe {
    aspect-ratio: 16/9;
  }

  &__videoplayer {
		display: none;
		opacity: 0;
	}
}
